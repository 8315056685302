export default function defer() {
    var deferred = {};
    var promise = new Promise(function(resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    deferred.promise = promise;
    const fn = () => promise;
    fn.resolve = deferred.resolve;
    fn.reject = deferred.reject;
    return fn;
}
