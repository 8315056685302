import {
    FETCH_SEARCH_REQUEST,
    FETCH_SEARCH_SUCCESS,
    FETCH_SEARCH_ERROR,
    FETCH_SEARCH_RESET,
    FETCH_SUGGESTIONS_REQUEST,
    FETCH_SUGGESTIONS_SUCCESS,
    FETCH_SUGGESTIONS_ERROR,
    FETCH_SUGGESTIONS_RESET
} from '../constants/actionTypes';

import apiFetchReducer from './api-fetch-reducer';

export const searchProducts = apiFetchReducer(FETCH_SEARCH_REQUEST, FETCH_SEARCH_SUCCESS, FETCH_SEARCH_ERROR, FETCH_SEARCH_RESET);

export const searchSuggestions = apiFetchReducer(FETCH_SUGGESTIONS_REQUEST, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_ERROR, FETCH_SUGGESTIONS_RESET);
