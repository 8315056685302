import { toggleReduced, fetchBasket, fetchUser, toggleReducedSidebar } from '../state/actions';
import setUberToken from '@ynap/auth-state/src/actions/setUberToken';

export default function createEventListeners(store) {
    store.dispatch(fetchUser());

    document.addEventListener('togglereduced', event => {
        const force = event.detail ? event.detail.force : undefined;
        store.dispatch(toggleReduced(force));
        store.dispatch(toggleReducedSidebar(event?.detail?.sidebar || false));
    });

    document.addEventListener('basketchange', event => {
        const { lastItemAdded = undefined } = event.detail ?? {};
        store.dispatch(fetchBasket(lastItemAdded));
    });

    document.addEventListener('userchange', event => {
        const uberToken = event.detail && event.detail.uberToken;
        const { lastItemAdded = undefined } = event.detail ?? {};

        if (uberToken) {
            store.dispatch(setUberToken(uberToken));
        }

        // if the ubertoken hasn't been set it could now be invalid - so we need
        // to show the logged out state in this case.
        store.dispatch(fetchUser());
        store.dispatch(fetchBasket(lastItemAdded));
    });
}
