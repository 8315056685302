export const trackSubCategoryClick = (navType, subCategoryLabel) => ({
    event: `${navType} - click`,
    effect: 'navigate',
    attributes: {
        navChild: subCategoryLabel.toLowerCase()
    }
});

export const getSubCategoryAttributes = (itemTitle, targetName) => ({
    attributes: {
        navParent: (targetName || itemTitle).toLowerCase()
    }
});
