import defer from '../src/Utils/defer';
import addToBag from './addToBag';
import addToWishlist from './addToWishlist';
import getAllCustomerSegments from '../src/Utils/getAllCustomerSegments';
import { setLocaleSwitchVisibility, setWebPushAnalyticsData } from '../../../state/actions';

export default function register(win, store) {
    if (win) {
        const SF = (win.SF = {});
        SF.getUserSummary = defer();
        SF.getCustomerSegments = async () => {
            const userSummary = await window?.SF.getUserSummary();
            if (!userSummary) return [];
            const { customerSegments } = userSummary;
            const allCustomerSegments = getAllCustomerSegments(customerSegments);
            const customerSegmentNames = allCustomerSegments.map(item => item?.customerSegmentName);
            return customerSegmentNames;
        };
        SF.addToBag = async ({ partNumber, quantity }) => {
            const props = {
                partNumber,
                quantity,
                ...store.getState()
            };
            const response = await addToBag(props);

            if (response.success) {
                SF.notifyBasketChange();
            }

            return response;
        };
        SF.addToWishlist = async (products = []) => {
            const props = {
                products,
                ...store.getState()
            };
            const response = await addToWishlist(props);

            if (response.success) {
                SF.notifyWishlistChange();
            }

            return response;
        };
        SF.getContactDetails = () => {
            // State of contact details is set only on server side and does not change until country change and full page refresh
            const { response = {}, error } = store.getState().contactDetails;
            return !error ? response.body : { customerCare: { telephone: '', email: '' } };
        };
        SF.triggerChangeCountryFlow = (visible = true, destCountry = null) => {
            return store.dispatch(setLocaleSwitchVisibility(visible, destCountry));
        };
        SF.triggerWebpushNotification = ({ analyticsData }) => {
            return store.dispatch(
                setWebPushAnalyticsData({
                    analyticsData
                })
            );
        };
        SF.toggleReduced = (detail = { force: true, sidebar: false }) => document.dispatchEvent(new CustomEvent('togglereduced', { detail }));
        SF.notifyBasketChange = (detail = {}) => document.dispatchEvent(new CustomEvent('basketchange', { detail }));
        SF.notifyUserChange = (detail = {}) => document.dispatchEvent(new CustomEvent('userchange', { detail }));
        SF.notifyCookieUpdate = () => document.dispatchEvent(new CustomEvent('cookiechange'));
        SF.notifyWishlistChange = () => document.dispatchEvent(new CustomEvent('wishlistchange'));
    }
}
