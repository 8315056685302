const getUserSegments = response => {
    const customerSegments = response?.customerSegments;

    const getAllCustomerSegments = customerSegments => {
        let AllCustomerSegmentNames = [];
        const allCustomerSegments = Object.keys(customerSegments);
        allCustomerSegments.forEach(element => {
            AllCustomerSegmentNames.push(...customerSegments[element]);
        });
        return AllCustomerSegmentNames;
    };

    if (customerSegments) {
        const allCustomerSegments = getAllCustomerSegments(customerSegments);
        const customerSegmentNames = allCustomerSegments.map(item => item?.customerSegmentName);
        return customerSegmentNames;
    }
};

export default getUserSegments;
