import { createStore, applyMiddleware } from 'redux';
import thunkInject from 'redux-thunk-inject';
import cookiesMiddleware, { getStateFromCookies } from '@ynap/redux-cookies-middleware';
import rootReducer from './rootReducer';

const fetchAndLog = __CLIENT__ ? fetch : require('../server/utils/fetchAndLog').default;

const cookiePaths = { uberToken: { name: 'ubertoken' } };
const enhancer = applyMiddleware(thunkInject(fetchAndLog), cookiesMiddleware(cookiePaths));

const configureStore = initialState => createStore(rootReducer, getStateFromCookies(initialState, cookiePaths), enhancer);

export default configureStore;
