import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import ToggleWrapper from '../Toggle/Toggle';

export const bem = new BEMHelper('MenuSlide');

const MenuSlide = props => {
    const { children, className, FRMenuItemClass } = props;

    return (
        <ToggleWrapper transitionName={bem()} {...props}>
            <div className={bem(null, null, className ? className : ['menu-slider', FRMenuItemClass].join(' '))}>{children}</div>
        </ToggleWrapper>
    );
};

MenuSlide.propTypes = {
    children: PropTypes.node,
    handleClick: PropTypes.func,
    toggle: PropTypes.bool,
    overlay: PropTypes.bool
};

MenuSlide.defaultProps = {
    handleClick: null,
    toggle: false,
    children: null,
    overlay: true
};

export default MenuSlide;
