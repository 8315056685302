import {
    FETCH_COUNTRIES_REQUEST,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_ERROR
} from '../constants/actionTypes';

import apiFetchReducer from './api-fetch-reducer';

const countriesReducer = apiFetchReducer(
    FETCH_COUNTRIES_REQUEST,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_ERROR
);

export default countriesReducer;