const getHeader = state => {
    const { reduced, prehomepage, countries, locale, config, user, multiShopCookie } = state;

    if (state.countries.error || !countries.response.body) {
        return {
            responseError: true
        };
    }

    const browsingCountry = countries.response.body.find(country => country && country.countryCode === locale.country) || {};

    const { cookiePolicy } = browsingCountry;
    // on stage 1 only add Floating Action Button on english
    const hasFab = locale?.language === 'en';
    return { reduced, prehomepage, cookiePolicy, locale, config, user, multiShopCookie, hasFab };
};

export default getHeader;
