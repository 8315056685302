import { TOGGLE_REDUCED_SIDEBAR } from '../constants/actionTypes';

const sidebar = (state = false, { type, sidebar }) => {
    switch (type) {
        case TOGGLE_REDUCED_SIDEBAR:
            return typeof sidebar === 'undefined' ? !state : sidebar;
        default:
            return state;
    }
};

export default sidebar;
