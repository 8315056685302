export const trackSearchBarOpened = () => ({
    event: 'search - opened'
});
export const trackSearchBarClosed = () => ({
    event: 'search - closed'
});
export const trackSearchStart = () => ({
    event: 'search - start'
});

export const trackSearchSelect = attributes => ({
    event: 'search - select',
    attributes
});

export const trackNoResults = searchTerm => ({
    event: 'search - autosuggest no results',
    attributes: {
        enteredTerm: searchTerm
    }
});
