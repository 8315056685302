import React, { PureComponent } from 'react';
import BEMHelper from '@ynap/bem-helper';
import Promo from '../../../../Promo/Promo';
import List from '../../List/List';

const bem = new BEMHelper('ThreeOne');

class ThreeOne extends PureComponent {
    render() {
        const { item, baseURL, mobile, hover } = this.props;

        return (
            <div className={bem('container')}>
                <div className={bem('threeLists')}>
                    <ul className={bem('listOne')}>
                        <List list={item} level={0} mobile={mobile} />
                    </ul>
                    <ul className={bem('listTwo')}>
                        <List list={item} level={1} mobile={mobile} />
                    </ul>
                    <ul className={bem('listThree')}>
                        <List list={item} level={2} mobile={mobile} />
                    </ul>
                </div>
                <div className={bem('promoSegment')}>
                    <Promo item={item} baseURL={baseURL} position={3} hover={hover} />
                </div>
            </div>
        );
    }
}

export default ThreeOne;
