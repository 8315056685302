import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import BEMHelper from '@ynap/bem-helper';
import ToggleWrapper from '../../Utils/Toggle/Toggle';
import { trackSearchBarOpened, trackSearchBarClosed } from './analytics';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
const SearchOverlay = React.lazy(() => import(/* webpackChunkName: "SearchOverlay" */ './SearchOverlay/SearchOverlay'));

export const bem = new BEMHelper('SearchPanel');
export const skeletonBem = new BEMHelper('skeletonOverlay');

@track({
    effect: 'form',
    category: {
        primaryCategory: 'internal search'
    }
})
class SearchPanel extends PureComponent {
    state = {
        open: false,
        error: false
    };

    @track((props, state) => (state.open ? trackSearchBarClosed() : trackSearchBarOpened()))
    handleClick = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleClickTracking = () => {
        const optimizely = window.optimizelyCreateInstance;
        optimizely?.onReady().then(() => {
            optimizely.track('global_search_clicks');
            optimizely.track('global_navandsearch_clicks');
        });
    };

    renderSkeleton() {
        return (
            <div className={skeletonBem()}>
                <div className={skeletonBem('content')}>
                    <div className={skeletonBem('input')}>
                        <div className={skeletonBem('searchIcon')} />
                    </div>
                    <div className={skeletonBem('close')} onClick={() => this.handleClick()} />
                </div>
            </div>
        );
    }

    render() {
        const { className, messages } = this.props;
        const { error } = this.state;

        return (
            <section className={bem(null, null, className)} role="search">
                <div
                    onClick={() => {
                        this.handleClick();
                        this.handleClickTracking();
                    }}
                    className={bem('wrapper')}
                >
                    <div key={'icon'} className={bem('icon')} />
                    <div className={bem('search-text')}>{messages.search.cta()}</div>
                </div>
                {!error && this.state.open ? (
                    <ToggleWrapper transitionName={bem()} handleClick={this.handleClick} toggle={this.state.open}>
                        <ErrorBoundary setError={() => this.setState({ error: true })} childType="search-overlay">
                            <Suspense fallback={this.renderSkeleton()}>
                                <SearchOverlay handleClick={this.handleClick} {...this.props} />
                            </Suspense>
                        </ErrorBoundary>
                    </ToggleWrapper>
                ) : null}
            </section>
        );
    }
}

SearchPanel.propTypes = {
    messages: PropTypes.shape({
        search: PropTypes.shape({
            cta: PropTypes.func.isRequired
        })
    }),
    className: PropTypes.string
};

SearchPanel.defaultProps = {
    className: null
};

export default SearchPanel;
