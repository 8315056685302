import React, { PureComponent } from 'react';
import BEMHelper from '@ynap/bem-helper';
import Promo from '../../../../Promo/Promo';
import List from '../../List/List';

const bem = new BEMHelper('OneTwo');

class OneTwo extends PureComponent {
    render() {
        const { item, baseURL, mobile, hover } = this.props;

        return (
            <div className={bem('container')}>
                <ul className={bem('list')}>
                    <List list={item} level={0} mobile={mobile} />
                </ul>
                <div className={bem('promoOne')}>
                    <Promo item={item} baseURL={baseURL} position={1} mobile={mobile} hover={hover} />
                </div>
                <div className={bem('promoTwo')}>
                    <Promo item={item} baseURL={baseURL} position={2} mobile={mobile} hover={hover} />
                </div>
            </div>
        );
    }
}

OneTwo.defaultProps = {};

export default OneTwo;
