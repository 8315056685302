import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import { linkRenderer } from '@ynap/router';

const bem = new BEMHelper('PrivateSaleBar');

const getPrivateSaleContent = (isSignedIn, privateSale, marketingGroups) => {
    if (!privateSale) return;

    const intersection = privateSale?.filter(item => {
        const result = item?.customerSegments?.filter(segment => marketingGroups?.includes(segment));
        if (result?.length) return item;
    });

    if (privateSale[0]?.customerSegments?.length === 0 && (intersection?.length === 0 || !isSignedIn)) {
        return privateSale[0];
    }

    return intersection[0];
};

const handleWithAnalytics = (e, content) => {
    const optimizely = window.optimizelyCreateInstance;
    optimizely?.onReady().then(() => {
        content && optimizely.track('visits_to_frontrow');
    });
};

const PrivateSaleBar = ({ locale, privateSale, marketingGroups, isSignedIn }) => {
    const content = getPrivateSaleContent(isSignedIn, privateSale, marketingGroups);
    const onClick = e => handleWithAnalytics(e, content);
    const privateSaleLocalisedLink = children => linkRenderer({}, [], locale.id, content?.linkUrl, children, onClick);

    return content ? (
        <div className={bem('wrapper')}>
            <div className={bem('container')}>
                <span>{content?.bannerText}</span>
                {privateSaleLocalisedLink(<span className={bem('link')}>{content?.linkText}</span>)}
            </div>
        </div>
    ) : null;
};

PrivateSaleBar.propTypes = {
    privateSale: PropTypes.array,
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    isSignedIn: PropTypes.bool,
    marketingGroups: PropTypes.array
};

export default PrivateSaleBar;
