import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { getMatchingTags } from '@ycos/coremedia-renderer';
import getTargetURL from '../../Utils/getTargetURL';

const bem = new BEMHelper('FooterLinks');

const FooterLinks = ({ footerMenu, withTitle }) => {
    if (!footerMenu) return null;
    const handleFooterLinkClick = cookieCentre => e => {
        if (cookieCentre) {
            e.preventDefault();
            window.tC?.privacyCenter?.showPrivacyCenter();
            return false;
        }
        return;
    };

    const getModifiersFromTags = tags => {
        return getMatchingTags(tags, 'FooterLink.Modifier.*')
            .reduce((acc, { groups }) => acc.concat(groups), [])
            .map(modifier => modifier.toLowerCase());
    };

    return (
        <Fragment>
            {(footerMenu.items || []).map(item =>
                item ? (
                    <div className={bem()} key={item?.title || ''}>
                        {withTitle ? <div className={bem('title')}>{item?.target?.title}</div> : null}
                        <section>
                            <ul className={bem('list')} key={(item?.title || '').replace(/\s+/g, '_').toLowerCase()}>
                                {(item.items || []).map(eachItem => {
                                    const { title, ynapParameter, tags } = eachItem;
                                    const key = title?.replace(/\s+/g, '_')?.toLowerCase();
                                    const cookieCentre = ynapParameter === 'Cookie Center';

                                    return (
                                        <li className={bem('link', getModifiersFromTags(tags))} key={key}>
                                            <a href={getTargetURL(eachItem)} onClick={e => handleFooterLinkClick(cookieCentre)(e)}>
                                                {title}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </section>
                    </div>
                ) : null
            )}
        </Fragment>
    );
};

FooterLinks.propTypes = {
    footerMenu: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        targetURL: PropTypes.string.isRequired
                    })
                )
            })
        ).isRequired
    }).isRequired,
    withTitle: PropTypes.bool
};

FooterLinks.defaultProptypes = {
    withTitle: false
};

export default FooterLinks;
