import { TOGGLE_REDUCED } from '../constants/actionTypes';

const reduced = (state = false, { type, force }) => {
    switch (type) {
        case TOGGLE_REDUCED:
            return typeof force === 'undefined' ? !state : force;
        default:
            return state;
    }
};

export default reduced;
