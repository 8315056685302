import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const postToWishlist = params => {
    const { baseUrl, brandId, country, clientId, partNumbers, uberToken } = params;

    invariant(baseUrl, 'baseUrl is a required param of postToWishlist()');
    invariant(brandId, 'brandId is a required param of postToWishlist()');
    invariant(country, 'country is a required param of postToWishlist()');
    invariant(clientId, 'clientId is a required param of postToWishlist()');
    invariant(partNumbers, 'partNumbers is a required param of postToWishlist()');
    invariant(uberToken, 'uberToken is a required param of postToWishlist()');

    const url = `${baseUrl}wcs/resources/store/${brandId}_${country}/wishlist/@default?addItem=true`;

    const headers = {
        'Content-Type': 'application/json',
        'X-Ubertoken': uberToken
    };
    const body = {
        item: partNumbers
    };

    return authenticatedRequest(
        new Request(url, {
            headers,
            method: 'PUT',
            body: JSON.stringify(body)
        }),
        null,
        clientId
    );
};

export default postToWishlist;
