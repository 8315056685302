import { ResponseError } from '@ynap/api-utils';
import { postToWishlist, getWCSHost } from '../../../api-utils/';

const addToWishlist = props => {
    const { config, locale, uberToken, products = [] } = props;
    const { wcs, brandId } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const { country } = locale;

    const partNumbers = products.map(product => ({ partNumber: product.partNumber }));
    const request = postToWishlist({
        uberToken,
        country,
        baseUrl,
        clientId,
        brandId,
        partNumbers
    });

    return fetch(request)
        .then(response => {
            if (response.ok) {
                return response.json().then(response => ({
                    response,
                    success: true
                }));
            }

            throw new ResponseError(response);
        })
        .catch(error => {
            return error.response.json().then(data => ({
                error: { ...error, errors: data.errors },
                success: false
            }));
        });
};

export default addToWishlist;
