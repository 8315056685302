import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import track from 'react-tracking';
import { trackIconInteraction } from '../../Header/analytics';

const bem = new BEMHelper('Basket');

@track()
class Basket extends PureComponent {
    componentDidMount() {
        const { fetchBasket } = this.props;

        fetchBasket();
    }

    @track((props, state, [event]) => trackIconInteraction('shopping bag', event.type))
    handleIconInteraction = () => {};

    render() {
        const { className, count, messages, locale } = this.props;
        const hasItems = count > 0;
        const wide = count > 99;

        return (
            <div className={bem(null, null, className)}>
                {hasItems ? <span className={bem('counter', { wide })}>{count}</span> : null}
                <a
                    className={bem('basket')}
                    href={`/${locale.id}${messages.nav.checkout.href()}`}
                    data-icon="shopping bag"
                    aria-label="shopping bag"
                    onClick={this.handleIconInteraction}
                    onMouseOver={this.handleIconInteraction}
                />
            </div>
        );
    }
}

Basket.propTypes = {
    fetchBasket: PropTypes.func.isRequired,
    count: PropTypes.number,
    className: PropTypes.string
};

export default Basket;
