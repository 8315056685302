import apiAction from '@ynap/api-fetch-action';
import { getCountries, getWCSHost } from '../../api-utils';

const fetchCountries = () => (dispatch, getState) => {
    const { locale, config } = getState();
    const { wcs, brandId } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const request = getCountries({ baseUrl, brandId, clientId, locale });

    return dispatch(apiAction(request, 'FETCH_COUNTRIES'));
};

export default fetchCountries;
