import { LOG_OUT_REQUEST, LOG_OUT_SUCCESS, LOG_OUT_ERROR } from '../constants/actionTypes';

const initialState = {
    isFetching: false,
    error: null
};

const logOut = (state = initialState, { type, error }) => {
    switch (type) {
        case LOG_OUT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOG_OUT_SUCCESS:
            return {
                isFetching: false,
                error: null
            };
        case LOG_OUT_ERROR:
            return {
                isFetching: false,
                error
            };
        default:
            return state;
    }
};

export default logOut;
