const initialState = {
    isFetching: false,
    error: false,
    response: {
        body: null,
        status: null
    }
};

const apiFetchReducer = (request, success, failure, reset) => (state = initialState, {
    body,
    status,
    type
}) => {
    switch (type) {
        case request:
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case success:
            return {
                ...state,
                isFetching: false,
                response: {
                    body,
                    status
                },
                error: false
            }
        case failure:
            return {
                ...state,
                isFetching: false,
                response: {
                    body,
                    status
                },
                error: true
            }
        case reset:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default apiFetchReducer;