import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ className, children, viewport, width, height }) => (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewport={viewport}>
        {children}
    </svg>
);

Svg.displayName = 'Svg';

Svg.propTypes = {
    className: PropTypes.string,
    viewport: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

export default Svg;
