import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const getSearchSuggestions = params => {
    const { baseUrl, brandId, country, searchTerm, clientId } = params;

    invariant(baseUrl, 'baseUrl is a required param of getSearchSuggestions()');
    invariant(brandId, 'brandId is a required param of getSearchSuggestions()');
    invariant(country, 'country is a required param of getSearchSuggestions()');
    invariant(clientId, 'clientId is a required param of getSearchSuggestions()');
    invariant(searchTerm, 'searchTerm is a required param of getSearchSuggestions()');
    const url = `${baseUrl}search/resources/store/${brandId}_${country.toLowerCase()}/sitecontent/suggestions/${searchTerm}`;
    return authenticatedRequest(new Request(url), null, clientId);
};

export default getSearchSuggestions;
