import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Overlay from '@ynap/overlay';
import BEMHelper from '@ynap/bem-helper';
import Flag from '@ynap/flag';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import { storeMismatchedCountryIfNeeded } from '../../../Utils/CountryMismatchUtil';
import handleChangeLanguage from '../utils/handleChangeLanguage';

export const bem = new BEMHelper('LocaleSwitchOverlay');

const propTypes = {
    messages: PropTypes.shape({
        localeSwitch: PropTypes.shape({
            changeCountry: PropTypes.shape({
                title: PropTypes.func.isRequired,
                description: PropTypes.func.isRequired
            }),
            languages: PropTypes.shape({
                en: PropTypes.func,
                ar: PropTypes.func
            }),
            itemsUnavailable: PropTypes.shape({
                title: PropTypes.func.isRequired
            })
        })
    }),
    destCountry: PropTypes.bool,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            countryCode: PropTypes.string.isRequired,
            languages: PropTypes.objectOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    langCountry: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ).isRequired,
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    toggleCountryOverlay: PropTypes.func.isRequired
};

const popularCountryCodes = ['US', 'GB', 'AU', 'HK'];

class LocaleSwitchOverlay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedAndPopularCountries: [],
            otherCountries: [],
            searchValue: ''
        };
        this.baseState = this.state;
    }

    componentDidMount() {
        const { countries, locale } = this.props;

        // Selected country should always be displayed as the first country in the list (alphabetical ordering does not apply to it).
        // Use 'Set' to return unique values.
        const selectedCountryCode = locale.country;
        const selectedAndPopularCountryCodes = [...new Set([selectedCountryCode].concat(popularCountryCodes))];

        // Use 'reduce' to preserve the exact order of how popular countries are defined.
        // Array lookup per iteration is mitigated by a small number of iterations (2-3 popular countries).
        const selectedAndPopularCountries = selectedAndPopularCountryCodes.reduce((acc, selectedAndPopularCountryCode) => {
            const selectedAndPopularCountry = countries.find(({ countryCode }) => countryCode === selectedAndPopularCountryCode);
            if (selectedAndPopularCountry) {
                acc.push(selectedAndPopularCountry);
            }
            return acc;
        }, []);

        const otherCountries = countries.filter(({ countryCode }) => !selectedAndPopularCountryCodes.includes(countryCode));

        this.setState({ selectedAndPopularCountries, otherCountries });
    }

    storeCountryIfNeeded = destCountry => {
        destCountry = destCountry || this.state.selectedAndPopularCountries[0];

        if (destCountry) {
            const { preferredLanguage: languageCode, countryCode } = destCountry;
            storeMismatchedCountryIfNeeded(languageCode, countryCode, this.props.config);
        }
    };

    handleRedirect = (event, country) => {
        event.preventDefault();
        this.storeCountryIfNeeded(country);
        // TODO: Use country code and temp symbol until country api can return the country name and reliably return currency data
        const tempSymbol = '$';
        const countryData = {
            name: country.countryCode,
            symbol: tempSymbol,
            currency: country.preferredCurrency
        };

        try {
            window.localStorage.setItem(`changeCountry`, JSON.stringify(countryData));
        } catch (error) {}

        const defaultLanguage = Object.keys(country.languages)[0];
        const newLocale = `/${country.languages[defaultLanguage].langCountry}`;
        const newPathname = window.location.pathname.replace(/^\/[a-z]{2}-[a-z]{2}/i, newLocale);

        // TODO: remove preflight check once all WCS localisation mappings have been implemented
        return fetch(newPathname)
            .then(res => {
                return res.ok ? window.location.assign(newPathname) : window.location.assign(newLocale);
            })
            .catch(() => window.location.assign(newLocale));
    };

    handleSearch = event => {
        const searchValue = event.target.value;
        this.setState({ searchValue });
    };

    render() {
        const { toggleCountryOverlay, messages } = this.props;
        return (
            <Overlay
                className={bem('countryChange')}
                handleBodyClick={toggleCountryOverlay}
                key="overlay"
                title={messages.localeSwitch.changeCountry.title()}
                onClose={toggleCountryOverlay}
            >
                {this.renderLayout()}
            </Overlay>
        );
    }

    renderSearchResult = () => {
        const { countries } = this.props;

        const searchCountryList = countries.filter(({ countryName }) => countryName.toLowerCase().startsWith(this.state.searchValue.toLowerCase()));

        return <ul>{this.renderCountries(searchCountryList)}</ul>;
    };

    getSearchedCountries = searchValue => {
        /*
         1. Search should be by full country name as well as by country code, e.g. 'gb' should return 'United Kingdom'.
         2. Search should perform 'starts with' check (not 'contains'), e.g. 'str' should not return 'Austria'.
         3. For multi-word country names search should check all words, e.g. 'sta' should return 'United States'.
         4. Search term should not be highlighted in the search results.
         5. Search should be case-insensitive, e.g. 'uNi' should return 'United Kingdom'.
         6. Search should ignore leading and trailing whitespaces in the search term, e.g. '(space)uni(space)' should still return 'United Kingdom'.
        */
        const searchValueLower = searchValue.toLowerCase().trim();
        return searchValueLower
            ? this.props.countries.filter(({ countryCode, countryName }) => {
                  const countryCodeLower = countryCode.toLowerCase();
                  const countryNameLower = countryName.toLowerCase();
                  const countryNameLowerWords = countryNameLower.split(' ');

                  return (
                      countryCodeLower.startsWith(searchValueLower) ||
                      countryNameLower.startsWith(searchValueLower) ||
                      countryNameLowerWords.some(countryNameLowerWord => countryNameLowerWord.startsWith(searchValueLower))
                  );
              })
            : [];
    };

    renderLayout = () => {
        const { countries, messages } = this.props;
        let popularCountriesList = [];

        popularCountryCodes.forEach(countryCode => {
            const temp = countries.find(country => country.countryCode === countryCode);
            temp && popularCountriesList.push(temp);
        });

        const countryListInOrder = countries.sort(function(a, b) {
            var countryA = a?.countryName?.toUpperCase();
            var countryB = b?.countryName?.toUpperCase();
            return countryA < countryB ? -1 : countryA > countryB ? 1 : 0;
        });

        return (
            <Fragment>
                {messages.localeSwitch.changeCountry.description() ? (
                    <div className={bem('description')}>{messages.localeSwitch.changeCountry.description()}</div>
                ) : null}
                <div className={bem('search')}>
                    <form className={bem('form')} onSubmit={this.handleOnSubmit}>
                        <div className={bem('iconContainer')}>
                            <div className={bem('icon', 'search')} onClick={this.handleOnSubmit} />
                        </div>
                        <input
                            type="text"
                            value={this.state.searchValue}
                            onChange={this.handleSearch}
                            placeholder={messages.search.cta()}
                            className={bem('input')}
                            autoFocus
                        />
                    </form>
                </div>

                <div className={bem('localesList')}>
                    {this.state.searchValue ? (
                        this.renderSearchResult(messages)
                    ) : (
                        <ul>
                            <div className={bem('divider')} />
                            {this.renderCountries(popularCountriesList, messages)}
                            <div className={bem('divider')} />
                            {this.renderCountries(countryListInOrder, messages)}
                        </ul>
                    )}
                </div>
            </Fragment>
        );
    };

    renderCountries = countries => {
        const { languages } = this.props.messages.localeSwitch;
        return countries.map((country, index) => {
            const availableLanguages = Object.keys(country.languages);
            const showLanguageToggle = availableLanguages.length > 1;
            const { locale } = this.props;
            const { countryCode, countryName } = country;
            const selected = locale.country === country.countryCode ? 'selected' : '';

            return (
                <li className={bem('item', [selected, showLanguageToggle && 'showLanguageToggle'])} key={country.countryCode}>
                    <div className={bem(showLanguageToggle && 'item-container')}>
                        <a
                            className={bem('link', `${countryCode}-${index}`)}
                            href={`/${country.languages[locale.language] ? country.languages[locale.language].langCountry : ''}/`}
                            onClick={event => this.handleRedirect(event, country)}
                        >
                            <Flag className={bem('flag')} countryISO={countryCode} />
                            <span className={bem('flag-label')}>{countryName}</span>
                        </a>
                        {showLanguageToggle && (
                            <LanguageToggle
                                countryCode={countryCode}
                                availableLanguages={availableLanguages}
                                messages={languages}
                                overlay={true}
                                handleChangeLanguage={handleChangeLanguage}
                            />
                        )}
                    </div>
                </li>
            );
        });
    };
}

LocaleSwitchOverlay.propTypes = propTypes;

export default LocaleSwitchOverlay;
