import { ResponseError } from '@ynap/api-utils';
import { getContactDetails } from '../../api-utils';
import { FETCH_CONTACT_DETAILS_REQUEST, FETCH_CONTACT_DETAILS_SUCCESS, FETCH_CONTACT_DETAILS_ERROR } from '../constants/actionTypes';

const fetchContactDetailsRequest = () => ({
    type: FETCH_CONTACT_DETAILS_REQUEST
});

const fetchContactDetailsSuccess = (response, status, country) => {
    const contactDetails = Object.keys(response.contactDetails).reduce((acc, contactType) => {
        const contacts = response.contactDetails[contactType];
        acc[contactType] = contacts[country] || contacts.GB;
        return acc;
    }, {});

    return {
        type: FETCH_CONTACT_DETAILS_SUCCESS,
        body: contactDetails,
        status
    };
};

const fetchContactDetailsError = (error) => ({
    type: FETCH_CONTACT_DETAILS_ERROR,
    body: error.message,
    status: error.response.status
});

const fetchContactDetails = () => (dispatch, getState, fetch) => {
    const { config, locale } = getState();
    const { brandId, contactDetails } = config;
    const { baseUrl } = contactDetails;
    const { country } = locale;

    dispatch(fetchContactDetailsRequest());

    return fetch(getContactDetails({ baseUrl, brandId }))
        .then(response => {
            if (response.ok) {
                return response.json().then(json => dispatch(fetchContactDetailsSuccess(json, response.status, country.toUpperCase())));
            }
            throw new ResponseError(response);
        })
        .catch(error => {
            dispatch(fetchContactDetailsError(error));
            throw error;
        });
};

export default fetchContactDetails;
