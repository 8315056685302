import { enums } from '@optimizely/react-sdk';

let disableCallback = false;

const contentSquareCallback = () => {
    if (!disableCallback) {
        disableCallback = true;
        if (window.CS_CONF) {
            window.CS_CONF.integrations = window.CS_CONF.integrations || [];
            window.CS_CONF.integrations.push('Optimizely Full Stack');
        }
    }
};

const onDecision = ({ userId, decisionInfo }) => {
    const { ruleKey, variationKey, flagKey, variables, decisionEventDispatched } = decisionInfo;
    if (decisionEventDispatched && ruleKey && ruleKey.indexOf('default-rollout') !== 0) {
        const customDecisionID = `${ruleKey}${ruleKey}${variationKey}${userId}`;

        if (flagKey === 'pdp_optimizely_test') {
            window?.optimizelyCreateInstance?.track('gtm-event-fire');
        }
        window.optDataLayer = window.optDataLayer || [];
        window.optDecisions = window.optDecisions || [];

        if (!window.optDecisions.includes(customDecisionID)) {
            window?.optDataLayer?.push({
                event: 'full-stacksite testing',
                category: {
                    primaryCategory: 'fs-on site testing',
                    subCategory: 'setup'
                },
                attributes: {
                    flagKey,
                    ruleKey,
                    variationKey,
                    userId,
                    variables: JSON.stringify(variables)
                }
            });
            window.optDecisions.push(customDecisionID);
          	window.optimizelyCreateInstance?.track('gtm-fire-optdatalayer'); // Testing GTM events 
          
            window._uxa.push(['trackDynamicVariable', { key: ruleKey, value: variationKey }]);
        }
    }
};

export default function addNotificationListener() {
    window?.optimizelyCreateInstance?.notificationCenter?.addNotificationListener(enums?.NOTIFICATION_TYPES?.DECISION, onDecision);
    window._uxa = window._uxa || [];
    window._uxa.push(['afterPageView', contentSquareCallback]);
}
