export const loadScriptWithSrc = src => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = src;
    document.body.appendChild(script);
};

export const loadScript = js => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.textContent = js;
    document.body.appendChild(script);
};
