import React, { PureComponent } from 'react';
import BEMHelper from '@ynap/bem-helper';
import Promo from '../../../../Promo/Promo';
import List from '../../List/List';

const bem = new BEMHelper('TwoTwo');

class TwoTwo extends PureComponent {
    render() {
        const { item, baseURL, mobile, hover } = this.props;

        return (
            <div className={bem('container')}>
                <div className={bem('twoLists')}>
                    <ul className={bem('listOne')}>
                        <List list={item} level={0} mobile={mobile} />
                    </ul>
                    <ul className={bem('listTwo')}>
                        <List list={item} level={1} mobile={mobile} />
                    </ul>
                </div>
                <div className={bem('twoPromos')}>
                    <div className={bem('promoOne')}>
                        <Promo item={item} baseURL={baseURL} position={2} hover={hover} />
                    </div>
                    <div className={bem('promoTwo')}>
                        <Promo item={item} baseURL={baseURL} position={3} hover={hover} />
                    </div>
                </div>
            </div>
        );
    }
}

export default TwoTwo;
