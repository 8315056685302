import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('ComplianceHKFooterInfo');

const ComplianceHKFooterInfo = ({ messages }) => {
    const { heading } = messages?.complianceHKFooterInfo;
    return (
        <div className={bem()}>
            <div className={bem('heading')}>
                <h2>{heading()}</h2>
            </div>
        </div>
    );
};

ComplianceHKFooterInfo.propTypes = {
    messages: PropTypes.object.isRequired
};

export default ComplianceHKFooterInfo;
