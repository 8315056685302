import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('Copyright');

const Copyright = ({ brand, messages }) => {
    const year = new Date().getFullYear();
    return (
        <section className={bem()}>
            <div className={bem('logo')} />
            <p className={bem('copyright')}>
                {`© ${messages.copyright.yearFrom()} ${year} ${brand} ${messages.copyright.copy()}`}
            </p>
            <div>
                {messages.copyright.extraCopy() && (
                    <p className={bem('copyright')}>
                        {messages.copyright.extraCopy()}
                    </p>
                )}
            </div>
        </section>
    );
};

const propTypes = {
    brand: PropTypes.string.isRequired
};

Copyright.propTypes = propTypes;

export default Copyright;
