import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { connect } from 'react-redux';
import { getHeader } from '../../../../../state/selectors';
import { LocaleSwitchContainer } from '../../common/Containers/Containers';
import { logOut } from '../../../../../state/actions';
import { trackIconInteraction } from '../../Header/analytics';
import PrivateSaleBar from '../PrivateSaleBar';

const bem = new BEMHelper('InfoBarMensWear');

const InfoBarMensWear = ({ messages, locale, privateSale, user, reduced, tracking, logOut }) => {
    const handleIconInteraction = () => tracking.trackEvent(trackIconInteraction());

    const { myOrders, trackOrder, createReturn, customerServiceInfo, deliveryInfo, returnsInfo } = messages.courtesyNav;
    const { marketingGroups, isSignedIn } = user?.response;

    const trackClick = link =>
        tracking.trackEvent({
            event: 'courtesy nav select',
            effect: 'navigate to courtesy nav destination',
            attributes: {
                navType: 'courtesy nav',
                navParent: 'header',
                navChild: link.toLowerCase()
            }
        });

    const handleLogOut = () => {
        tracking.trackEvent({
            event: 'logout',
            effect: 'log out',
            attributes: { logged: 'logged out' },
            category: {
                subCategory: 'header',
                primaryCategory: 'mini account',
                subCategory1: 'sign out'
            }
        });
        logOut();
    };

    if (reduced) {
        return null;
    }

    return (
        <div className={bem('wrapper')}>
            <div className={bem('courtesyNav')}>
                {/* <div className={bem('courtesyNavWrapper')}> */}
                <div className={bem('login')}>
                    <ul>
                        <li className={bem('link', 'signIn')}>
                            {isSignedIn ? (
                                <>
                                    <a
                                        className={bem('icon', 'account')}
                                        onMouseOver={handleIconInteraction}
                                        data-icon="my account"
                                        href={`/${locale.id}${messages.nav.myAccount.href()}`}
                                    />
                                    <div key="myAccount" className={bem('accountTitle')}>
                                        <a
                                            href={`/${locale.id}${messages.nav.myAccount.href()}`}
                                            data-icon="my account"
                                            onClick={handleIconInteraction}
                                            onMouseOver={handleIconInteraction}
                                        >
                                            {user.response.firstName
                                                ? user.response.firstName.length < 12
                                                    ? `${messages.nav.myAccount.text({ name: user.response.firstName })}`
                                                    : messages.nav.account.text()
                                                : messages.nav.account.text()}
                                        </a>
                                        <span className={bem('signout', null, 'signout')} onClick={handleLogOut}>
                                            <a>{messages.nav.signOut.text()}</a>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={bem('icon')} />
                                    <div key="myAccount" className={bem('accountTitle')}>
                                        <a href={`/${locale.id}${messages.nav.signIn.href()}`}>{messages.nav.signIn.text()}</a>
                                    </div>
                                </>
                            )}
                        </li>
                        <li className={bem('link')}>
                            <LocaleSwitchContainer messages={messages} />
                        </li>
                    </ul>
                </div>
                <div className={bem('courtesyNavContainer')}>
                    <ul>
                        {isSignedIn ? (
                            <li className={bem('link')}>
                                <a href={`/${locale.id}${myOrders.href()}`} onClick={() => trackClick(myOrders.text())}>
                                    {myOrders.text()}
                                </a>
                            </li>
                        ) : (
                            <>
                                <li className={bem('link')}>
                                    <a href={`/${locale.id}${trackOrder.href()}`} onClick={() => trackClick(trackOrder.text())}>
                                        {trackOrder.text()}
                                    </a>
                                </li>
                                <li className={bem('link')}>
                                    <a href={`/${locale.id}${createReturn.href()}`} onClick={() => trackClick(createReturn.text())}>
                                        {createReturn.text()}
                                    </a>
                                </li>
                            </>
                        )}
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${customerServiceInfo.href()}`} onClick={() => trackClick(customerServiceInfo.text())}>
                                {customerServiceInfo.text()}
                            </a>
                        </li>
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${deliveryInfo.href()}`} onClick={() => trackClick(deliveryInfo.text())}>
                                {deliveryInfo.text()}
                            </a>
                        </li>
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${returnsInfo.href()}`} onClick={() => trackClick(returnsInfo.text())}>
                                {returnsInfo.text()}
                            </a>
                        </li>
                    </ul>
                </div>
                {/* </div> */}
            </div>
            <PrivateSaleBar privateSale={privateSale} locale={locale} marketingGroups={marketingGroups} isSignedIn={isSignedIn} />
        </div>
    );
};

InfoBarMensWear.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    messages: PropTypes.shape({
        nav: PropTypes.func.isRequired
    }).isRequired
};

export default track({})(connect(getHeader, { logOut })(InfoBarMensWear));
