import React, { useState, useEffect, useMemo } from 'react';
import BEMHelper from '@ynap/bem-helper';
import track from 'react-tracking';
import { connect } from 'react-redux';
import { getHeader } from '../../../../../state/selectors';
import Carousel from '@ynap/carousel/src/Carousel/Carousel';
import getComponentBySegment from '../../Utils/Segments/getComponentBySegment';
import { userSegmentsBuilder } from './userSegmentsBuilder';
import ReactMarkdown from 'react-markdown';
import AppStateProvider from '@ynap/immutables-provider';
import { getSegmentsFromParams } from '../../Utils/Segments/getSegmentsFromParams';
import PreventSSR from '../PreventSSR/PreventSSR';
import { getTags } from '@ycos/coremedia-renderer';
import { createpromoObject, trackOnPageLoad, trackOnClick } from './analytics';

export const bem = new BEMHelper('BenefitsBar');

const Link = ({ children, href, className, onClick, link }) => {
    return link ? (
        <a href={href} className={className} onClick={onClick}>
            {children}
        </a>
    ) : (
        <div className={className}>{children}</div>
    );
};

const SlotLink = ({ locale, sale, itemsLength, tracking, position, item, userSegments }) => {
    if (item.teaser) {
        item.anchorpoint = item.teaser.anchorpoint;
        item.teaserText = item.teaser.teaserText;
        item.teaserTextPlain = item.teaser.teaserTextPlain;
        item.pictures = item.media;
    }
    const icon = item?.pictures?.[0]?.imageUrl;
    const tags = getTags(item?.tags, 'PromoType');
    const link = item?.anchorpoint && item?.anchorpoint.startsWith('http') ? `${item?.anchorpoint}` : `/${locale.id}${item?.anchorpoint}`;
    return (
        <Link
            href={link}
            className={bem('link')}
            onClick={() => trackOnClick(tracking, createpromoObject(link, item?.teaserTextPlain, icon, position, item?.contentID, tags, userSegments))}
            link={item?.anchorpoint}
        >
            <div className={bem('nativeContainer')}>
                <div className={bem('centre')}>
                    {icon && <img src={icon} alt={'banner icon'} className={bem('icon')} />}
                    {item?.teaserText && (
                        <div className={bem('textContainer', { sale, singleItem: itemsLength === 1 })}>
                            <ReactMarkdown>{item.teaserText}</ReactMarkdown>
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
};

const renderSlot = (item, locale, tracking, sale, index, itemsLength, userSegments) => {
    return (
        <SlotLink
            locale={locale}
            sale={sale}
            key={index}
            tracking={tracking}
            itemsLength={itemsLength}
            position={index}
            item={item}
            userSegments={userSegments}
        />
    );
};

const renderBenefitsBar = (benefitsBar, userSegments, itemsLength, tracking, locale) => {
    return benefitsBar.map((item, index) => {
        if (item.type === 'collection' && item.layoutVariant === 'osc-segmented-experience') {
            const segmentedItem = getComponentBySegment(item.items, userSegments);
            const sale = segmentedItem?.tags?.find(tag => tag.name === 'red');
            return renderSlot(segmentedItem, locale, tracking, sale, index, itemsLength, userSegments);
        }
        const sale = item?.tags?.find(tag => tag.name === 'red');
        return renderSlot(item, locale, tracking, sale, index, itemsLength, userSegments);
    });
};

const BenefitsBar = ({ reduced, benefitsBar, locale, tracking, user: { response: customer, hasFetched }, config }) => {
    const itemsLength = benefitsBar?.length;
    if (reduced || !itemsLength) {
        return null;
    }

    const userSegments = useMemo(() => {
        let queryParamSegments = undefined;
        if (typeof window !== 'undefined') {
            const segmentsParams = new URLSearchParams(window?.location?.search).get('segments');
            queryParamSegments = getSegmentsFromParams(segmentsParams);
        }
        const onTheFlySegments = userSegmentsBuilder(customer);
        const allSegments = customer?.marketingGroups ? [...onTheFlySegments, ...customer?.marketingGroups] : onTheFlySegments;
        return (config?.isPreview && queryParamSegments) || allSegments;
    }, [customer?.hasOrder]);

    const [slide, setSlide] = useState(0);
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window?.innerWidth : null);

    useEffect(() => {
        const resizeListener = () => {
            setWidth(window?.innerWidth);
        };
        window.addEventListener('resize', resizeListener);
        let interval;
        if (width && width < 1024) {
            interval = setInterval(() => {
                setSlide(slide < itemsLength - 1 ? slide + 1 : 0);
            }, 5000);
        }
        return () => {
            window.removeEventListener('resize', resizeListener);
            clearInterval(interval);
        };
    }, [slide, width]);

    //fire onPageLoad analytics once user info has been fetched
    useEffect(() => {
        if (hasFetched) {
            const analyticsItems = benefitsBar.map((item, index) => {
                if (item.type === 'collection' && item.layoutVariant === 'osc-segmented-experience') {
                    const segmentedItem = getComponentBySegment(item.items, userSegments);
                    const tags = getTags(segmentedItem?.tags, 'PromoType');
                    const link =
                        segmentedItem?.anchorpoint && segmentedItem?.anchorpoint.startsWith('http')
                            ? `${segmentedItem?.anchorpoint}`
                            : `/${locale.id}${segmentedItem?.anchorpoint}`;
                    return createpromoObject(
                        link,
                        segmentedItem?.teaserTextPlain,
                        segmentedItem?.pictures?.[0]?.imageUrl,
                        index,
                        segmentedItem?.contentID,
                        tags,
                        userSegments
                    );
                } else {
                    const link = item?.anchorpoint && item?.anchorpoint.startsWith('http') ? `${item?.anchorpoint}` : `/${locale.id}${item?.anchorpoint}`;
                    const tags = getTags(item?.tags, 'PromoType');
                    return createpromoObject(link, item?.teaserTextPlain, item?.pictures?.[0]?.imageUrl, index, item?.contentID, tags, userSegments);
                }
            });
            trackOnPageLoad(tracking, analyticsItems);
        }
    }, [hasFetched]);

    return (
        <div className={bem('container')}>
            <PreventSSR>
                {benefitsBar && (
                    <>
                        <div className={bem('desktop')}>{renderBenefitsBar(benefitsBar, userSegments, itemsLength, tracking, locale)}</div>
                        <div className={bem('mobile')}>
                            {itemsLength > 1 ? (
                                <AppStateProvider value={{ appState: { ...locale } }}>
                                    <Carousel defaultIndex={slide} classNames={{ slideClass: 'mobile-benefitsBar' }}>
                                        {renderBenefitsBar(benefitsBar, userSegments, itemsLength, tracking, locale)}
                                    </Carousel>
                                </AppStateProvider>
                            ) : (
                                renderBenefitsBar(benefitsBar, userSegments, itemsLength, tracking, locale)
                            )}
                        </div>
                    </>
                )}
            </PreventSSR>
        </div>
    );
};

export default track({})(connect(getHeader)(BenefitsBar));
