import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';

const bem = new BEMHelper('Message');

const Message = ({ title, copy, className, onClose, children }) => (
    <section className={bem(null, null, className)}>
        {onClose && <button className={bem('close')} type="button" onClick={onClose} aria-label="close" />}
        <h4 className={bem('title', null, className)}>{title}</h4>
        <p className={bem('copy', null, className)}>{copy}</p>
        {children}
    </section>
);

const propTypes = {
    title: PropTypes.string.isRequired,
    copy: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node
};

Message.propTypes = propTypes;
export default Message;
