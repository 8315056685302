import { REGISTER_EMAIL_REQUEST, REGISTER_EMAIL_SUCCESS, REGISTER_EMAIL_ERROR, REGISTER_EMAIL_RESET } from '../constants/actionTypes';

const initialState = {
    isFetching: false,
    success: false,
    error: null,
};

const register = (state = initialState, { type, error }) => {
    switch (type) {
        case REGISTER_EMAIL_REQUEST:
            return {
                isFetching: true
            };
        case REGISTER_EMAIL_SUCCESS:
            return {
                success: true
            };
        case REGISTER_EMAIL_ERROR:
            return {
                error
            };
        case REGISTER_EMAIL_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default register;
