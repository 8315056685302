import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Overlay from '@ynap/overlay';
import BEMHelper from '@ynap/bem-helper';
import { ProductSummaryWithLink } from '@ynap/product-summary/src/ProductSummaryWithLink/ProductSummaryWithLink';
import { withOptimizely } from '@ynap/optimizely-utils';

export const bem = new BEMHelper('RecentlyViewed');

const ANIMATION_DURATION = 201;
const OVERLAY_SLIDE_IN_DELAY = 2000;

const RecentlyViewed = ({ messages, hideProductInformation, optimizely }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [animationState, setAnimationState] = useState('slideIn');

    useEffect(() => {
        // Every Page except shopping bag and checkout
        const isPageAllowed = !window?.location?.pathname?.includes('checkout');
        // logic to check if should show or not
        // If the user has seen any PDP and if the Recently Viewed was not open yet
        if (!sessionStorage.getItem('sf_recentlyViewed_isAvailable') && localStorage.getItem('sf_recentlyViewed') && isPageAllowed) {
            setProducts(JSON.parse(localStorage.getItem('sf_recentlyViewed')).reverse());
            setTimeout(() => {
                setIsOpen(true);
                sessionStorage.setItem('sf_recentlyViewed_isAvailable', 'false');
                optimizely?.track?.('global_entrypage', { url: window?.location?.href });
            }, OVERLAY_SLIDE_IN_DELAY);
        }
    }, []);

    return (
        isOpen &&
        products && (
            <Overlay
                className={bem('container', animationState)}
                handleBodyClick={() => {
                    optimizely?.track?.('global_clicksondisabledarea');
                    setAnimationState('slideOut');
                    setTimeout(() => {
                        setIsOpen(false);
                    }, ANIMATION_DURATION);
                }}
                key="overlay"
                title={messages.recentlyViewed.title()}
                onClose={() => {
                    optimizely?.track?.('global_clicksonclosebutton');
                    setAnimationState('slideOut');
                    setTimeout(() => {
                        setIsOpen(false);
                    }, ANIMATION_DURATION);
                }}
            >
                {products.map((product, i) => (
                    <div
                        key={product.productId}
                        onClick={() => {
                            optimizely?.track?.('global_clicksonproductdrawer');
                            optimizely?.track?.('global_positionofclicksindrawer', { productPosition: i });
                        }}
                    >
                        <ProductSummaryWithLink
                            className={hideProductInformation ? 'hideProductInformation' : ''}
                            messages={messages}
                            product={product}
                            nativelink={true}
                        />
                    </div>
                ))}
            </Overlay>
        )
    );
};

RecentlyViewed.defaultProps = {
    messages: {
        recentlyViewed: {
            title: () => ''
        },
        product: {
            unavailableProduct: () => ''
        },
        considered: {
            badge: () => ''
        },
        price: {
            taxIncluded: () => '',
            currencyExchange: () => '',
            discountPercentage: () => '',
            ddpInformation: () => ''
        }
    },
    hideProductInformation: false,
    optimizely: {
        track: () => {}
    }
};

RecentlyViewed.propTypes = {
    messages: PropTypes.object,
    hideProductInformation: PropTypes.bool,
    optimizely: PropTypes.object
};

export default withOptimizely(RecentlyViewed);
