import { getCookie } from '@ynap/redux-cookies-middleware/src/cookieApi';

export default function setMultiShopCookie(config, country, cookie) {
    let cookieValue = cookie;
    const expiryDaysInSeconds = 60 * 60 * 24 * 90;

    if (config?.disableCountries?.includes(country)) {
        cookieValue = 'womens';
    }

    if (!cookieValue) {
        const url = window?.location?.pathname;

        const shopRoute = config?.shops?.find(route => {
            return route.dropCookiePaths?.find(path => {
                const regex = new RegExp(path);
                return regex.exec(url);
            });
        });

        if (shopRoute) {
            cookieValue = shopRoute.key;
        }
    }

    const cookieName = config?.cookie?.name;

    const currentCookieValue = getCookie(cookieName);
    const genderHasChanged = cookieValue !== currentCookieValue;

    if ((cookieValue && !document.cookie.includes(cookieName)) || (cookieValue && genderHasChanged)) {
        document.cookie = `${cookieName}=${cookieValue}; path=/; max-age=${expiryDaysInSeconds};`;
    }
}
