import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { OverlayAnimation } from '@ynap/overlay';
import BEMHelper from '@ynap/bem-helper';

export const bem = new BEMHelper('Toggle');

export class Toggle extends PureComponent {
    componentDidMount() {
        if (this.props.bodyLock) {
            const classNameArray = bem('body', 'locked').split(' ');
            document.body.classList.add(...classNameArray);
        }
        document.addEventListener('keydown', this.handleEscKey, false);
    }

    componentWillUnmount() {
        if (this.props.bodyLock) {
            const classNameArray = bem('body', 'locked').split(' ');
            document.body.classList.remove(...classNameArray);
        }
        document.removeEventListener('keydown', this.handleEscKey, false);
    }

    handleEscKey = e => {
        if (e.key === 'Escape') {
            this.props.handleClick();
        }
    };

    render() {
        const { children, overlay, handleClick, desktopSlider } = this.props;
        return (
            <Fragment>
                {children}
                {overlay ? <div className={bem(desktopSlider ? 'desktopSlider' : 'overlay', null, 'overlay')} onClick={handleClick} /> : null}
            </Fragment>
        );
    }
}

class ToggleWrapper extends PureComponent {
    render() {
        const { transitionName, toggle, children } = this.props;
        return <OverlayAnimation transitionName={transitionName}>{toggle ? <Toggle {...this.props}>{children}</Toggle> : null}</OverlayAnimation>;
    }
}

Toggle.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.node,
    overlay: PropTypes.bool,
    bodyLock: PropTypes.bool
};

Toggle.defaultProps = {
    overlay: true,
    bodyLock: true
};

ToggleWrapper.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.node,
    toggle: PropTypes.bool,
    transitionName: PropTypes.string
};

ToggleWrapper.defaultProps = {
    transitionName: '',
    toggle: false
};

export default ToggleWrapper;
