import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Toggle from '@ynap/toggle';
import { OptimizelyFeature } from '@ynap/optimizely-utils';
import track from 'react-tracking';
import SocialIcons from '../../common/SocialIcons/SocialIcons';
import { socialAccounts, brand } from '../../mockData/ton';
import { FooterLinksContainer, ContactPanelContainer, FastUserRegistrationContainer } from '../../common/Containers/Containers';
import Copyright from '../../common/Copyright/Copyright';
import dispatch from '@ynap/analytics/src/dispatch';
import LiveChat from '@ynap/live-chat';
import { connect } from 'react-redux';
import { getFooter } from '../../../../../state/selectors';
import ScriptContainer from './ScriptContainer';
import PreventSSR from '../../common/PreventSSR/PreventSSR';
import Webpush from '../../common/Webpush/webpush';
import RecentlyViewed from '../../common/RecentlyViewed';
import AffirmUSFooterInfo from '../../common/AffirmUSFooterInfo/AffirmUSFooterInfo';
import ComplianceHKFooterInfo from '../../common/ComplianceHKFooterInfo/ComplianceHKFooterInfo';

const bem = new BEMHelper('Footer');

const SocialAccounts = ({ messages }) => (
    <div className={bem('signupAndSocial')}>
        <SocialIcons accounts={socialAccounts} messages={messages} />
    </div>
);

export const FastUserRegistration = ({ messages }) => (
    <div className={bem('fastUserRegistration')}>
        <FastUserRegistrationContainer messages={messages} />
    </div>
);

const PaymentOptions = ({ klarna, affirm, country }) => (
    <div className={bem('paymentOptions')}>
        <div className={bem('visa')} />
        <div className={bem('mastercard')} />
        <div className={bem('amex')} />
        {klarna.enabled && klarna.enabledIn.includes(country) ? <div className={bem('klarna')} /> : null}
        {affirm.enabled && affirm.enabledIn.includes(country) ? <div className={bem('affirm')} /> : null}
    </div>
);

const SiteSwitch = ({ messages }) => (
    <div className={bem('siteSwitch')}>
        <a className={bem('nap')} href={messages.externalLinks.nap()} aria-label="net-a-porter.com" />
        <a className={bem('mrp')} href={messages.externalLinks.mrp()} aria-label="mrporter.com" />
    </div>
);
@track(
    {
        category: {
            subCategory: 'footer'
        }
    },
    { dispatch }
)
export class Footer extends PureComponent {
    render() {
        const { messages, reduced, locale, config, IsSignedIn, webpush } = this.props;
        const { language, country } = locale;
        const { liveChatBrand } = config;
        // as we know current live TON US site hide all other languages, but SF still have DE FR avaiable
        // only show Affirm message in english on US site,
        const showUsAffirmMessage = country === 'US' && language === 'en';
        // only show HK Compliance message in english on HK site,
        const showHkComplianceMessage = country === 'HK' && language === 'en';
        const liveChatCountry = country === 'US' ? country : undefined;

        if (reduced) {
            return liveChatBrand && <LiveChat brand={liveChatBrand} language={language} country={liveChatCountry} />;
        }

        return (
            <div className={bem()}>
                <div className={bem('layoutGrid')}>
                    <div className={`${bem('desktop')} ${bem('footerLinks')}`}>
                        <FooterLinksContainer withTitle={true} />
                    </div>
                    <Webpush country={country} config={config} messages={messages} analyticsData={webpush.analyticsData} />
                    {!IsSignedIn && <FastUserRegistration messages={messages} />}
                    <Toggle title={messages.footer.footerLinks()} className={`${bem('mobile')} ${bem('footerLinks')}`}>
                        <FooterLinksContainer withTitle={true} />
                    </Toggle>
                    <div className={`${bem('desktop')} ${bem('contactAndLocale')}`}>
                        <ContactPanelContainer messages={messages} />
                        {showUsAffirmMessage && <AffirmUSFooterInfo messages={messages} />}
                        {showHkComplianceMessage && <ComplianceHKFooterInfo messages={messages} />}
                    </div>
                    <div className={`${bem('mobile')} ${bem('contactAndLocale')}`}>
                        <Toggle title={messages.footer.contactAndLocale()}>
                            <ContactPanelContainer messages={messages} />
                        </Toggle>
                        {showUsAffirmMessage && <AffirmUSFooterInfo messages={messages} />}
                        {showHkComplianceMessage && <ComplianceHKFooterInfo messages={messages} />}
                    </div>
                    <SocialAccounts 
                        messages={messages} 
                        showUsAffirmMessage={showUsAffirmMessage} 
                        showHkComplianceMessage={showHkComplianceMessage} 
                    />
                    <div />
                    <PaymentOptions klarna={config.klarna} affirm={config.affirm} country={locale.country} />
                    <SiteSwitch messages={messages} />
                    <div className={bem('copyright')}>
                        <Copyright brand={brand} messages={messages} />
                    </div>
                </div>
                {liveChatBrand && <LiveChat brand={liveChatBrand} language={language} country={liveChatCountry} />}
                <PreventSSR>
                    <ScriptContainer config={config} />
                </PreventSSR>
                <OptimizelyFeature feature={['web00027_recentlyviewedmodule_landingpage_desktop', 'web00028_recentlyviewedmodule_landingpage_mobile']}>
                    {props => {
                        const { variationKey, isEnabled } = props;
                        return (
                            isEnabled &&
                            variationKey !== 'control' && (
                                <RecentlyViewed messages={messages} hideProductInformation={variationKey?.includes?.('v2') ? false : true} />
                            )
                        );
                    }}
                </OptimizelyFeature>
            </div>
        );
    }
}

Footer.propTypes = {
    messages: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
};

export default connect(getFooter)(Footer);
