import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from '../constants/actionTypes';

const initialState = {
    isFetching: false,
    error: null,
    response: {
        isSignedIn: false
    },
    hasFetched: false
};

const user = (state = initialState, { type, response, error }) => {
    switch (type) {
        case FETCH_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_USER_SUCCESS:
            return {
                isFetching: false,
                response: { ...response },
                hasFetched: true,
                error: null
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error
            };
        default:
            return state;
    }
};

export default user;
