const handleChangeLanguage = async (event, countryCode, selectedLanguage) => {
    event.preventDefault();

    const newLocale = `/${selectedLanguage}-${countryCode}`;
    const newPathname = window.location.pathname.replace(/^\/[a-z]{2}-[a-z]{2}/i, newLocale);

    // TODO: remove preflight check once all WCS localisation mappings have been implemented
    return await fetch(newPathname)
        .then(res => {
            return res.ok ? window.location.assign(newPathname) : window.location.assign(newLocale);
        })
        .catch(() => window.location.assign(newLocale));
};

export default handleChangeLanguage;
