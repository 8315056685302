import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

export function getRequestLocale(locale) {
    if (typeof locale === 'undefined' || !locale || !locale.language) {
        return '';
    }

    const language = locale.language;
    const countryMap = {
        en: 'en_US',
        de: 'de_DE',
        ar: 'ar_AE',
        ja: 'ja_JP',
        ko: 'ko_KR'
    };

    const mappedCountry = countryMap?.[language];
    return mappedCountry ? `?locale=${mappedCountry}` : '';
}

const getCountries = params => {
    const { baseUrl, brandId, clientId, locale } = params;

    invariant(baseUrl, 'baseUrl is a required param of getCountries()');
    invariant(clientId, 'clientId is a required param of getCountries()');
    invariant(brandId, 'brandId is a required param of getCountries()');

    const headers = {
        'Content-Type': 'application/json',
        'x-ibm-client-Id': clientId
    };

    const queryString = getRequestLocale(locale);

    const url = `${baseUrl}wcs/resources/store/${brandId}/v2/countries${queryString}`;
    return authenticatedRequest(new Request(url, { headers: new Headers(headers), method: 'GET' }));
};

export default getCountries;
