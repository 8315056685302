import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { connect } from 'react-redux';
import { getHeader } from '../../../../../state/selectors';
import PrivateSaleBar from '../PrivateSaleBar';

const bem = new BEMHelper('InfoBar');

const InfoBar = ({ messages, locale, privateSale, user, reduced, tracking }) => {
    const { myOrders, trackOrder, createReturn, customerServiceInfo, deliveryInfo, returnsInfo } = messages.courtesyNav;
    const { marketingGroups, isSignedIn } = user?.response;

    const trackClick = link =>
        tracking.trackEvent({
            event: 'courtesy nav select',
            effect: 'navigate to courtesy nav destination',
            attributes: {
                navType: 'courtesy nav',
                navParent: 'header',
                navChild: link.toLowerCase()
            }
        });

    if (reduced) {
        return null;
    }

    return (
        <>
            <div className={bem('courtesyNav')}>
                <div className={bem('courtesyNavContainer')}>
                    <ul>
                        {isSignedIn ? (
                            <li className={bem('link')}>
                                <a href={`/${locale.id}${myOrders.href()}`} onClick={() => trackClick(myOrders.text())}>
                                    {myOrders.text()}
                                </a>
                            </li>
                        ) : (
                            <>
                                <li className={bem('link')}>
                                    <a href={`/${locale.id}${trackOrder.href()}`} onClick={() => trackClick(trackOrder.text())}>
                                        {trackOrder.text()}
                                    </a>
                                </li>
                                <li className={bem('link')}>
                                    <a href={`/${locale.id}${createReturn.href()}`} onClick={() => trackClick(createReturn.text())}>
                                        {createReturn.text()}
                                    </a>
                                </li>
                            </>
                        )}
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${customerServiceInfo.href()}`} onClick={() => trackClick(customerServiceInfo.text())}>
                                {customerServiceInfo.text()}
                            </a>
                        </li>
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${deliveryInfo.href()}`} onClick={() => trackClick(deliveryInfo.text())}>
                                {deliveryInfo.text()}
                            </a>
                        </li>
                        <li className={bem('link')}>
                            <a href={`/${locale.id}${returnsInfo.href()}`} onClick={() => trackClick(returnsInfo.text())}>
                                {returnsInfo.text()}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <PrivateSaleBar privateSale={privateSale} locale={locale} marketingGroups={marketingGroups} isSignedIn={isSignedIn} />
        </>
    );
};

InfoBar.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    messages: PropTypes.shape({
        nav: PropTypes.func.isRequired
    }).isRequired
};

export default track({})(connect(getHeader)(InfoBar));
