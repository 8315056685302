import { addToCart } from '@ynap/api-utils/src/cart';
import { Identity } from '@ynap/api-utils';
import { getWCSHost } from '../../../api-utils/';

const getAddToCartParams = ({ config, uberToken, locale, quantity, partNumber }) => {
    const { language, country, langId } = locale;
    const { brandId, wcs } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });

    return {
        baseUrl,
        brandId,
        country,
        language,
        uberToken,
        clientId,
        langId,
        quantity,
        partNumber
    };
};

const addToBag = async props => {
    const { config, locale, partNumber, quantity } = props;
    let { uberToken } = props;
    const isGuestUser = !uberToken;

    if (isGuestUser) {
        // Creates uberToken for guest user as it is a required param for addToCart
        const { baseUrl, brandId, country, clientId } = getAddToCartParams({ config, locale });
        const guestIdentityRequest = Identity.createGuestIdentity({ baseUrl, brandId, country, clientId });
        try {
            const response = await fetch(guestIdentityRequest, { method: 'POST' });
            if (!response.ok) {
                throw new Error(response);
            }
            const json = await response.json();
            uberToken = json.Ubertoken;
        } catch (e) {}
    }

    try {
        const addToCartRequest = addToCart(getAddToCartParams({ config, locale, uberToken, partNumber, quantity }));
        const response = await fetch(addToCartRequest);
        const json = await response.json();

        if (response.ok) {
            isGuestUser && window.SF.notifyUserChange({ uberToken });
            return {
                response: json,
                success: true
            };
        }

        const { errors = [] } = json || {};
        const errorMessage = errors?.[0]?.errorKey || response.statusText;

        return {
            error: errorMessage,
            success: false
        };
    } catch (error) {
        return {
            error,
            success: false
        };
    }
};

export default addToBag;
