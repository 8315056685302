export const buildSiteMerchandisingItems = (rawItems = [], title = '') => {
    return rawItems.reduce((acc, item) => {
        const { target, targetURL, picture, promoPosition } = item;
        const campaignName = item.title;
        if (target && picture && targetURL) {
            acc.push({
                placement: 'mega nav',
                position: `${title.toLocaleLowerCase()} - ${promoPosition}`,
                campaignName,
                imageUrl: picture.imageUrl,
                destinationUrl: targetURL
            });
        }
        return acc;
    }, []);
};

export const getPromoDisplayData = item => {
    const { title = '', items = [] } = item;
    const rawSiteMerchandising = items
        .filter(el => el.isPromo)
        .reduce((acc, el) => {
            return [...acc, ...el.items];
        }, []);
    const siteMerchandising = buildSiteMerchandisingItems(rawSiteMerchandising, title);
    const promoData = {
        event: 'mega nav content impressions',
        effect: 'content impressions',
        category: {
            primaryCategory: 'site merchandising',
            subCategory: title.toLocaleLowerCase()
        },
        attributes: {
            siteMerchandising
        }
    };

    return siteMerchandising && siteMerchandising.length > 0 ? promoData : null;
};

export const getPromoClickData = ({ title, picture, targetURL, promoPosition }, categoryName = '') => {
    let promoData = (title, targetURL)
        ? {
              event: 'mega nav content click',
              effect: 'content click',
              category: {
                  primaryCategory: 'site merchandising',
                  subCategory: categoryName.toLocaleLowerCase()
              },
              attributes: {
                  siteMerchandising: [
                      {
                          placement: 'mega nav',
                          position: `${categoryName.toLocaleLowerCase()} - ${promoPosition}`,
                          campaignName: title,
                          imageUrl: picture && picture.imageUrl ? picture.imageUrl : '',
                          destinationUrl: targetURL
                      }
                  ]
              }
          }
        : null;

    return promoData;
};

const embedPromoInfo = (item, promoPosition) =>
    item
        ? {
              ...item,
              items: item?.items?.map(el => ({ ...el, promoPosition, isPromo: true })),
              promoPosition,
              isPromo: true
          }
        : {};

export const addPromoInfoMiddleware = (items, layoutVariant) => {
    switch (layoutVariant) {
        case 'menu-four-one':
            return [items[0], items[1], items[2], items[3], embedPromoInfo(items[4], 1)];
        case 'menu-three-one':
            return [items[0], items[1], items[2], embedPromoInfo(items[3], 1)];
        case 'menu-two-two':
            return [items[0], items[1], embedPromoInfo(items[2], 1), embedPromoInfo(items[3], 2)];
        case 'menu-one-two':
            return [items[0], embedPromoInfo(items[1], 1), embedPromoInfo(items[2], 2)];
    }

    return items;
};
