import getWCSHost from '../../../../api-utils/getWCSHost';

export default class liveStream {
    currentProps;
    constructor(props) {
        const autoplayParam = getParameterByName('autoplayLiveShopping');
        const showIdParam = getParameterByName('showId');
        this.currentProps = props;

        // hasFab => if there is float action button to trigger the livestream player
        // passed from Header
        if (autoplayParam || showIdParam || this.currentProps.hasFab) {
            this.renderLivestream({ showId: autoplayParam || showIdParam, hasFab: true });
        }

        // if url contain hash with liveStream, make sure page will trigger the livestream
        window.addEventListener('hashchange', () => {
            if (location.hash === '#live-stream') {
                this.renderLivestream(autoplayParam || showIdParam);
            }
        });
    }

    renderLivestream = ({ showId, hasFab }) => {
        const { language = 'en', country = 'gb', currency = 'GBP' } = document.body.dataset;
        const langIdMap = {
            en: `en_US`,
            ko: `ko_KR`,
            de: `de_DE`,
            ja: `ja_JP`,
            ar: `ar_AE`
        };

        window.liveStreamProducts = {};
        window.liveStreamSkuMapping = {};
        window.onBambuserLiveShoppingReady = player => {
            player.configure({
                locale: `${language}-${country.toUpperCase()}`,
                currency,
                buttons: {
                    dismiss: player.BUTTON.MINIMIZE
                }
            });

            player.on(player.EVENT.READY, () => {
                // do something when player is ready?
            });

            player.on(player.EVENT.PROVIDE_PRODUCT_DATA, event => {
                const { baseUrl, clientId } = getWCSHost({ wcs: this.currentProps.config.wcs });
                event.products.forEach(({ ref, id }) => {
                    const fetchUrl = `${baseUrl}search/resources/store/theoutnet_${country}/productview/${ref}?locale=${langIdMap[language]}`;
                    fetch(fetchUrl, {
                        headers: {
                            'x-ibm-client-id': clientId
                        }
                    })
                        .then(response => response.json())
                        .then(productResponse => {
                            const product = productResponse.products[0];
                            if (!product) {
                                throw new Error('No product found in api');
                            }
                            const urlPath = product.seo && product.seo.seoURLKeyword ? product.seo.seoURLKeyword : `/${product.partNumber}`;
                            if (urlPath) {
                                player.updateProduct(id, factory =>
                                    factory.inheritFromPlaceholder().publicUrl(`https://www.theoutnet.com/${language}-${country}/shop/product${urlPath}`)
                                );
                            }
                            player.updateProduct(id, factory =>
                                factory.product(p =>
                                    p
                                        .brandName(product.designerName)
                                        .name(product.name)
                                        .sku(product.partNumber)
                                        .variations(v => mapVariations(v, product))
                                )
                            );
                        })
                        // eslint-disable-next-line
                        .catch(err => console.error('Error hydrating product data', err));
                });
            });
            player.on(player.EVENT.ADD_TO_CART, (addedItem, callback) => {
                addsku(addedItem.sku, callback);
            });

            player.on(player.EVENT.UPDATE_ITEM_IN_CART, (event, callback) => {
                const diff = event.quantity - event.previousQuantity;
                if (diff < 0) {
                    for (let i = 0; i > diff; i--) {
                        // This PROBABLY needs to change to update line item
                        removesku(event.sku, callback, this.currentProps);
                    }
                }
                if (diff > 0) {
                    addsku(event.sku, callback, diff);
                }
            });

            player.on(player.EVENT.SYNC_CART_STATE, function() {
                // this is ONLY called when player is reopened
            });

            player.on(player.EVENT.CHECKOUT, showCheckout.bind(null, player, country, language));
        };

        const addsku = async (sku, callback, quantity = 1) => {
            const response = await window.SF.addToBag({
                partNumber: sku,
                quantity
            });

            if (!response.success) {
                callback(false);
            } else {
                // In order to enable navigating the rest of the website while a video is playing,
                // Bambuser creates an iframe where it injects a copy of our website.
                // We therefore need to target the iframe and dispatch the same event in order to update the basket ticker
                document.querySelector('div[id^=livecommerce-surf]>iframe').contentWindow.SF.notifyBasketChange();
                callback(true);
            }
        };

        async function removesku(sku, callback) {
            // fail everything
            callback(false);
        }

        if (!window.initBambuserLiveShopping) {
            window.initBambuserLiveShopping = function(item) {
                window.initBambuserLiveShopping.queue.push(item);
            };
            window.initBambuserLiveShopping.queue = [];
            var scriptNode = document.createElement('script');
            scriptNode['src'] = 'https://lcx-embed.bambuser.com/default/embed.js';
            document.body.appendChild(scriptNode);
        }

        // If there is a FAB, we do not auto start the player
        // this should only happen if `autoplayParam` or `showIdParam` are present in the queryString
        !hasFab &&
            window.initBambuserLiveShopping({
                showId,
                type: 'overlay'
            });
    };
}

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const mapVariations = (v, product) => {
    const displayableColours = (product.productColours && product.productColours.filter(({ displayable }) => displayable)) || [];
    const categories = product.masterCategory || (product.salesCategories && product.salesCategories.length && product.salesCategories[0]) || {};
    if (!displayableColours) {
        throw new Error('No displayable colours');
    }

    return displayableColours.map(colour => {
        const name = colour.shortDescription || colour.name;
        const images =
            (colour.imageViews.length &&
                colour.imageTemplate &&
                colour.imageViews.map(shotType => colour.imageTemplate.replace('{view}', shotType).replace('{width}', '1000'))) ||
            [];
        const skus = (colour.sKUs && colour.sKUs.filter(displayable => displayable)) || [];
        window.liveStreamProducts[colour.partNumber] = {
            productInfo: {
                productName: name,
                manufacturer: product.designerNameEN || product.designerName
            },
            category: {
                primaryCategory: categories.labelEN || categories.label || '',
                subCategory1: (categories.child && (categories.child.labelEN || categories.child.label)) || '',
                subCategory2: (categories.child && categories.child.child && (categories.child.child.labelEN || categories.child.child.label)) || ''
            }
        };
        return v()
            .imageUrls(images)
            .name(name)
            .sku(colour.partNumber)
            .attributes(a => a.colorName(colour.label))
            .sizes(s => {
                return skus.map(sku => {
                    const price = sku.price || {};
                    const currentPrice = price.sellingPrice && price.sellingPrice.amount / price.sellingPrice.divisor;
                    const originalPrice = price.wasPrice && price.wasPrice.amount / price.wasPrice.divisor;
                    window.liveStreamSkuMapping[sku.partNumber] = colour.partNumber;
                    return s()
                        .name(sku.size.labelSize)
                        .inStock(sku.buyable)
                        .sku(sku.partNumber)
                        .price(pr =>
                            pr
                                .currency(price.currency && price.currency.label)
                                .current(currentPrice)
                                .original(originalPrice)
                        );
                });
            });
    });
};

export const showCheckout = (player, country, language) => {
    player.showCheckout(window.location.origin + `/${language}-${country.toLowerCase()}/checkout`);
};
