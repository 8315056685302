import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('ContactPanel');

class ContactPanel extends PureComponent {
    render() {
        const { messages, addClass, responseError } = this.props;
        if (responseError) return null;

        const { title, copyBeforeLink, copyAfterLink, href, customerCare } = messages.contact;

        return (
            <section className={bem(null, null, addClass)}>
                <h4 className={bem('title')}>{title()}</h4>
                <div className={bem('details')}>
                    {copyBeforeLink()}{' '}
                    <a className={bem('cc-link')} href={href()}>
                        {customerCare()}
                    </a>
                    {copyAfterLink()}
                </div>
            </section>
        );
    }
}

ContactPanel.defaultProps = {
    addClass: ''
};

ContactPanel.propTypes = {
    messages: PropTypes.shape({
        contact: PropTypes.shape({
            title: PropTypes.func.isRequired,
            copyBeforeLink: PropTypes.func.isRequired,
            copyAfterLink: PropTypes.func.isRequired,
            href: PropTypes.func.isRequired,
            customerCare: PropTypes.func.isRequired
        }).isRequired
    }).isRequired,
    isEip: PropTypes.bool,
    addClass: PropTypes.string,
    responseError: PropTypes.bool
};

export default ContactPanel;
