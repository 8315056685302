import { ResponseError } from '@ynap/api-utils';
import { FETCH_BASKET_REQUEST, FETCH_BASKET_SUCCESS, FETCH_BASKET_ERROR, BASKET_CLEAR_LAST_ITEM } from '../constants/actionTypes';
import { getBasket, getWCSHost } from '../../api-utils';

const fetchBasketRequest = () => ({
    type: FETCH_BASKET_REQUEST
});

const fetchBasketSuccess = (response, lastItemAdded) => ({
    type: FETCH_BASKET_SUCCESS,
    response,
    lastItemAdded
});

const fetchBasketError = error => ({
    type: FETCH_BASKET_ERROR,
    error
});

const fetchBasket = lastItemAdded => (dispatch, getState, fetch) => {
    const { config, uberToken, locale } = getState();
    const { brandId, wcs } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const { country } = locale;
    const isCheckout = window?.location?.href?.includes('/checkout');

    dispatch(fetchBasketRequest());

    if (!uberToken || isCheckout) {
        return dispatch(fetchBasketSuccess({}));
    }

    return fetch(getBasket({ baseUrl, country, brandId, clientId, uberToken }))
        .then(response => {
            if (response.ok) {
                return response.json().then(json => {
                    dispatch(fetchBasketSuccess(json, lastItemAdded));
                });
            }
            if (response.status === 401 || response.status === 403) {
                return dispatch(fetchBasketSuccess({}));
            }
            throw new ResponseError(response);
        })
        .catch(error => {
            dispatch(fetchBasketError(error.message));
            throw error;
        });
};

export const basketClearLastItem = () => ({
    type: BASKET_CLEAR_LAST_ITEM
});

export default fetchBasket;
