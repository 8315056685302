import setCookie, { getCookie } from '@ynap/redux-cookies-middleware/src/cookieApi';

const DEFAULT_GEO_IP_COUNTRY_CODE = 'GB';
const EXPIRY_MS = 1000 * 60 * 60 * 24 * 7; // 7 days in milliseconds

export const getMismatchedCountryCodeKey = countryCode => `sf_country_check_${countryCode}`;

export const getGeoIPCountryCode = () => getCookie('geoIP') || DEFAULT_GEO_IP_COUNTRY_CODE;

export const checkCountryMismatch = (geoIPCountryCode, countryCode) => !!(geoIPCountryCode && countryCode !== geoIPCountryCode);

export const storeMismatchedCountryIfNeeded = (languageCode, countryCode, config) => {
    // Set lang_iso and country_iso cookies for locer
    const { names: cookieNames, expiryDays = 90, secure = false } = config.localisation.cookies;
    const { languageIso, countryIso } = cookieNames;
    setCookie(languageIso, languageCode, expiryDays, secure);
    setCookie(countryIso, countryCode, expiryDays, secure);

    const geoIPCountryCode = getGeoIPCountryCode();
    const isCountryMismatch = checkCountryMismatch(geoIPCountryCode, countryCode);
    if (isCountryMismatch) {
        try {
            localStorage.setItem(getMismatchedCountryCodeKey(countryCode), Date.now());
        } catch (e) {}
    }
};

export const getMismatchedCountry = (countryCode, countries) => {
    const geoIPCountryCode = getGeoIPCountryCode();

    const isCountryMismatch = checkCountryMismatch(geoIPCountryCode, countryCode);
    if (isCountryMismatch) {
        const mismatchedCountry = countries.find(country => country.countryCode === geoIPCountryCode);
        if (mismatchedCountry) {
            try {
                const storageValue = localStorage.getItem(getMismatchedCountryCodeKey(countryCode));
                if (!storageValue || Date.now() - parseInt(storageValue) > EXPIRY_MS) {
                    return mismatchedCountry;
                }
            } catch (e) {}
        }
    }

    return undefined;
};
