import { multipleRequestApiAction } from '@ynap/api-fetch-action';
import { getSearchResultsByTerm } from '@ynap/api-utils/src/catalogue';
import { getSearchSuggestions, getWCSHost } from '../../api-utils';
import { FETCH_SEARCH_RESET, FETCH_SUGGESTIONS_RESET } from '../constants/actionTypes';

export const fetchSuggestions = (browsingGenderPreference, searchTerm, options = { suggest: true, products: true }) => (dispatch, getState) => {
    const { config, locale, countries } = getState();
    const {
        wcs,
        brandId,
        search: { maxProducts }
    } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const { country, language } = locale;
    const currentCountry = countries.response.body.find(each => each.countryCode.toLowerCase() === country.toLowerCase());
    const wcsLocale = currentCountry.languages[language] ? currentCountry.languages[language].locale : currentCountry.languages['en'].locale;
    const encodedSearchTerm = encodeURIComponent(searchTerm.trim());
    const suggestionsRequest = getSearchSuggestions({ baseUrl, brandId, country, searchTerm: encodedSearchTerm, clientId });
    // @TODO remove getSearchResultsByTerm once the suggestions api is updated
    const searchOptions = {
        pageSize: maxProducts,
        pageNumber: 1,
        metaData: false,
        locale: wcsLocale
    };

    const genderSearchOptions =
        browsingGenderPreference === 'womens'
            ? {
                  excludeCategory: '/mens',
                  ...searchOptions
              }
            : { category: '/mens', ...searchOptions };
    const searchRequest = getSearchResultsByTerm({ baseUrl, brandId, country, language, term: encodedSearchTerm, clientId, options: genderSearchOptions });
    const requests = [];
    options.suggest && requests.push({ request: suggestionsRequest, prefix: 'FETCH_SUGGESTIONS', required: true });
    options.products && requests.push({ request: searchRequest, prefix: 'FETCH_SEARCH', required: true });
    return dispatch(multipleRequestApiAction(requests));
};

export const reset = () => dispatch => {
    dispatch({
        type: FETCH_SUGGESTIONS_RESET
    });
    dispatch({
        type: FETCH_SEARCH_RESET
    });
};

const search = {
    fetchSuggestions,
    reset
};

export default search;
