export const trackSideNavDrillDown = categoryLabel => ({
    event: 'side nav - level 2',
    effect: 'flyout',
    attributes: {
        navDetails: categoryLabel?.toLowerCase()
    }
});

export const trackTopNavCategoryClick = (categoryLabel, parentCategory) => ({
    event: 'top nav - click',
    effect: 'navigate',
    attributes: {
        navChild: categoryLabel?.toLowerCase(),
        navParent: categoryLabel?.toLowerCase() == parentCategory.toLowerCase() ? 'n/a (top level)' : parentCategory.toLowerCase()
    }
});
