import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const getUserSummary = params => {
    const { baseUrl, brandId, country, clientId, uberToken } = params;

    invariant(baseUrl, 'baseUrl is a required param of getUserSummary()');
    invariant(brandId, 'brandId is a required param of getUserSummary()');
    invariant(country, 'country is a required param of getUserSummary()');
    invariant(uberToken, 'uberToken is a required param of getUserSummary()');
    invariant(clientId, 'clientId is a required param of getUserSummary()');

    const url = `${baseUrl}wcs/resources/store/${brandId}_${country.toLowerCase()}/person/v2/@self/summary`;

    return authenticatedRequest(new Request(url), uberToken, clientId);
};

export default getUserSummary;
