import { getTags } from '@ycos/coremedia-renderer/src/utils/tagsUtils';

const getMenus = state => {
    if (!state.menus.response) {
        return {
            responseError: true
        };
    }
    const restructureRes = restructureV2toV1(state.menus.response);
    if (state.menus.error || !restructureRes.SFHeader || restructureRes.SFHeader.length < 1) {
        return {
            responseError: true
        };
    }
    const headerItems = restructureRes.SFHeader[0].items;
    const footerMenu = { items: [restructureRes.SFFooter[0]] };
    const headerMenu = {
        items: stripHiddenItems(headerItems)
    };
    const privateSale = getPrivateSale([restructureRes.SFPrivateEvents?.[0]]);
    const locale = state.locale;
    const benefitsBar = restructureRes.SFBenefitsBar?.[0].items;
    // get product listing url template from config
    const getProductCategoryUrl = path => {
        const { locale, config } = state;
        return `/${locale.id.toLowerCase()}${config.productCategoryUrl}${path}`;
    };

    // TODO: Figure out a nice way to do this
    const isSignedIn = state.user.response.isSignedIn;

    return {
        headerMenu,
        footerMenu,
        config: state.config,
        isSignedIn,
        locale,
        getProductCategoryUrl,
        privateSale: privateSale,
        benefitsBar
    };
};

const getPrivateSale = items => {
    const privateSale = items.find(item => item?.target?.key === 'hiddenRibbonPage');
    return privateSale?.items?.map(item => {
        const { tags, target } = item || {};
        const customerSegments = getTags(tags, 'CustomerSegments');
        if (!customerSegments) return null;
        let url = target?.targetURL ? target.targetURL : target?.href;
        return {
            customerSegments,
            linkText: item.title,
            linkUrl: url,
            targetTitle: item?.target?.title,
            bannerText: item.teaserTextPlain || item.teaser?.teaserTextPlain
        };
    });
};

//remove all non meganav items
const stripHiddenItems = items => items.filter(item => item.layoutVariant && item.layoutVariant.startsWith('menu-'));

const restructureV2toV1 = originalJSON => {
    const restructuredJSON = { ...originalJSON?.body };
    if (restructuredJSON.version !== '2.0') {
        return restructuredJSON;
    }
    restructuredJSON.placements.forEach(placement => (restructuredJSON[placement.name] = placement.content));
    delete restructuredJSON.placements;
    if (restructuredJSON.seoMetadata) {
        const { seoHTMLTitle, seoHTMLMetaDescription, seoSegment, keywords, ...seoMetadata } = restructuredJSON.seoMetadata;
        if (seoHTMLTitle) {
            restructuredJSON.seoHTMLTitle = seoHTMLTitle;
        }
        if (seoHTMLMetaDescription) {
            restructuredJSON.seoHTMLMetaDescription = seoHTMLMetaDescription;
        }
        if (seoSegment) {
            restructuredJSON.seoSegment = seoSegment;
        }
        if (keywords) {
            restructuredJSON.freeKeywords = keywords;
        }
        restructuredJSON.seoMetadata = seoMetadata;
    }
    return restructuredJSON;
};

export default getMenus;
