import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import PropTypes from 'prop-types';

export const bem = new BEMHelper('LanguageToggle');

const LanguageToggle = ({ countryCode, availableLanguages, messages, currentLanguage, overlay = false, modifier, handleChangeLanguage }) => {
    const modifierOveride = modifier || (overlay && 'overlay');
    return (
        <div className={bem('languageSwitch', modifierOveride)}>
            {availableLanguages.map(language => (
                <a
                    onClick={event => {
                        handleChangeLanguage(event, countryCode.toLowerCase(), language);
                    }}
                    href={`/${language}-${countryCode.toLowerCase()}/`}
                    key={messages[language]()}
                    className={bem('language', currentLanguage === language && 'active')}
                >
                    {messages[language]()}
                </a>
            ))}
        </div>
    );
};

const propTypes = {
    messages: PropTypes.shape({
        en: PropTypes.func.isRequired,
        ar: PropTypes.func.isRequired
    }),
    countryCode: PropTypes.string.isRequired,
    availableLanguages: PropTypes.array.isRequired,
    currentLanguage: PropTypes.string,
    overlay: PropTypes.bool
};

LanguageToggle.propTypes = propTypes;

export default LanguageToggle;
