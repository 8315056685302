import { FETCH_MENUS_REQUEST, FETCH_MENUS_SUCCESS, FETCH_MENUS_ERROR } from '../constants/actionTypes';

import apiFetchReducer from './api-fetch-reducer';

const menusReducer = apiFetchReducer(
    FETCH_MENUS_REQUEST,
    FETCH_MENUS_SUCCESS,
    FETCH_MENUS_ERROR
);

export default menusReducer;
