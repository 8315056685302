// Zoom in/out animation runs from 42px (info bar height)
// until 100px (looks smooth)
// Zooms from 24px to 16px and vice-versa
const FINAL_ZOOM_BREAKPOINT = 100;
const INITIAL_ZOOM_FONTSIZE = 24;
const FINAL_ZOOM_FONTSIZE = 16;

export default function handleMinimisedHeaderOnScrollBehaviour() {
    const headerWrapper = document.getElementById('header-wrapper');
    const header = document.getElementById('header');
    const headerMain = document.getElementById('header-main');
    const headerHeight = header.getBoundingClientRect().height;
    const infoBar = document.getElementById('infoBar');
    const benefitsBar = document.getElementById('benefitsBar');
    const logo = document.querySelector('a[class$=logo--minimisedHeaderExperiment]');
    const infoBarHeight = infoBar.getBoundingClientRect().height;

    window.onscroll = function() {
        const currentScrollPos = window.scrollY;

        const resizeObserver = new ResizeObserver(entries => {
            const height = Math.round(entries[0].contentRect.height);

            if (headerWrapper.offsetHeight !== height) {
                headerWrapper.style.height = height + 'px';
            }
        });

        resizeObserver.observe(headerMain);

        if (currentScrollPos > infoBarHeight) {
            headerWrapper.classList.add('minimisedHeaderExperiment');
            infoBar.classList.add('hideMe');
            benefitsBar.setAttribute('style', `margin-top: ${infoBarHeight + headerHeight}px`);
        }

        if (currentScrollPos < infoBarHeight) {
            headerWrapper.classList.remove('minimisedHeaderExperiment');
            infoBar.classList.remove('hideMe');
            benefitsBar.setAttribute('style', `margin-top: 0`);
        }

        if (currentScrollPos < infoBarHeight) {
            logo.setAttribute('style', `height: ${INITIAL_ZOOM_FONTSIZE}px`);
        }

        if (currentScrollPos > FINAL_ZOOM_BREAKPOINT) {
            logo.setAttribute('style', `height: ${FINAL_ZOOM_FONTSIZE}px`);
        }

        if (currentScrollPos >= infoBarHeight && currentScrollPos <= FINAL_ZOOM_BREAKPOINT + infoBarHeight) {
            logo.setAttribute('style', `height: clamp(${FINAL_ZOOM_FONTSIZE}px, ${Math.ceil(lineEquation(window.scrollY))}px , ${INITIAL_ZOOM_FONTSIZE}px)`);
        }
    };

    // InfoBar can have variable height depending on how many items it has, so
    // Math 🤓
    // y = m*x + b (line equation)
    //
    // m => slope of line
    // b => point where the line intersects the Y axis
    //
    // solving for m
    // y => font size
    // x => how much we have have scrolled. We start shrinking the font size when we pass infoBarHeight, and we end 78px later (very arbitrary, it just seemed smooth)
    // m = (y2 - y1)/(x2 - x1):
    // m = (16 - 24)/(infoBarHeight - (infoBarHeigh + 78)) <=> m = -8/78
    const m = -8 / 78;

    // solving for b
    // y = m*x + b <=> b = y - m*x
    // in this case we can use the initial font size (24px) and where we want the animation to start (infoBarHeight)
    const b = 24 - m * infoBarHeight;

    // line equation
    // y = m * x + b
    const lineEquation = x => m * x + b;
}
