import React from 'react';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('SocialIcons');

const SocialIcons = ({ accounts, messages }) => (
    <section className={bem('container')}>
        {messages.social.title() && <p className={bem('title')}>{messages.social.title()}</p>}
        <ul className={bem()}>
            {accounts.map(({ title, href }) => {
                const id = title.replace(/\s+/g, '').toLowerCase();

                return (
                    <li className={bem('icon')} key={id}>
                        <a className={bem('link', id)} href={href} target="_blank" rel="noopener noreferrer" title={title}>
                            {title}
                        </a>
                    </li>
                );
            })}
        </ul>
    </section>
);

export default SocialIcons;
