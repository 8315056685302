import { connect } from 'react-redux';
import LocaleSwitch from '../LocaleSwitch/LocaleSwitch';
import FooterLinks from '../FooterLinks/FooterLinks';
import ContactPanel from '../ContactPanel/ContactPanel';
import SearchPanel from '../SearchPanel/SearchPanel';
import FastUserRegistration from '../FastUserRegistration/FastUserRegistration';
import Basket from '../Basket/Basket';

import { getMenus, getContactDetails, getCountries, actions, getBasket, getSearch, getFooter } from '../../../../../state';

const { fetchUser, fetchBasket, registerEmail, search } = actions;

export const FooterLinksContainer = connect(getMenus)(FooterLinks);
export const LocaleSwitchContainer = connect(getCountries)(LocaleSwitch);
export const ContactPanelContainer = connect(getContactDetails, { fetchUser })(ContactPanel);

export const SearchContainer = connect(getSearch, { ...search })(SearchPanel);
export const BasketContainer = connect(getBasket, { fetchBasket })(Basket);

export const FastUserRegistrationContainer = connect(getFooter, registerEmail)(FastUserRegistration);
