import React, { PureComponent } from 'react';
import BEMHelper from '@ynap/bem-helper';
import FourOne from './layoutVarients/four-one/index';
import ThreeOne from './layoutVarients/three-one/index';
import TwoTwo from './layoutVarients/two-two/index';
import OneTwo from './layoutVarients/one-two/index';
import TwoOne from './layoutVarients/two-one/index';

export const bem = new BEMHelper('SecondaryMenu');

const LAYOUTS = {
    'menu-four-one': FourOne,
    'menu-three-one': ThreeOne,
    'menu-two-two': TwoTwo,
    'menu-one-two': OneTwo,
    'menu-two-one': TwoOne
};

class SecondaryMenu extends PureComponent {
    render() {
        const { item, messages, imageBaseUrl, handleClick, mobile, hover, FRMenuItemClass } = this.props;
        const { target } = item;
        const LayoutComponent = LAYOUTS[item.layoutVariant];
        const key = target && target.id ? target.id : '';

        const body = LayoutComponent ? (
            <LayoutComponent item={item} baseURL={imageBaseUrl} key={key} mobile={mobile} hover={hover} />
        ) : (
            <div>{messages.error.megaNav()}</div>
        );

        return (
            <div className={bem()}>
                <div className={bem('header', null, FRMenuItemClass)}>
                    <button className={bem('back')} onClick={handleClick} />
                    <div>{item && item.title}</div>
                </div>
                <div className={bem('body')}>{body}</div>
            </div>
        );
    }
}

export default SecondaryMenu;
