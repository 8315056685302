import React, { PureComponent, useState } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { withOptimizely } from '@optimizely/react-sdk';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { OptimizelyFeature } from '@ynap/optimizely-utils';
import { trackIconInteraction } from '../analytics';
import dispatch from '@ynap/analytics/src/dispatch';
import { connect } from 'react-redux';
import { getHeader } from '../../../../../state/selectors';
import { logOut } from '../../../../../state/actions';
import liveStream from '../../liveStream';
import { SearchContainer, BasketContainer, LocaleSwitchContainer } from '../../common/Containers/Containers';
import HeaderNotifications from '../../common/HeaderNotifications/HeaderNotifications';
import MyAccountNav from '../../common/MyAccountNav/MyAccountNav';
import Nav from '../../common/Nav/Nav';
import GenderSwitch from '../../common/GenderSwitch/GenderSwitch';
import PromptBasketItem from '../../common/PromptBasketItem/PromptBasketItem';
import setCookie from '@ynap/redux-cookies-middleware/src/cookieApi';

const bem = new BEMHelper('Header');

@track(
    {
        category: {
            subCategory: 'header'
        }
    },
    { dispatch }
)
class Header extends PureComponent {
    componentDidMount() {
        new liveStream(this.props);
    }

    @track((props, state, [event]) => trackIconInteraction(event.currentTarget.getAttribute('data-icon'), event.type))
    handleIconInteraction = () => {};

    setCookie = () => {
        // set cookie to expire in 1min so user has time to reload page
        const in1Min = new Date(new Date().getTime() + 60 * 1000);
        setCookie('meganav_click', true, in1Min);
    };

    render() {
        const { messages, reduced, prehomepage, user, logOut, locale, config, multiShopCookie } = this.props;
        const isSignedIn = user?.response?.isSignedIn;
        const menswearEnabled = config.multiShop?.enabled && !config.multiShop.disableCountries.includes(locale.country.toUpperCase());

        if (reduced) {
            if (typeof document !== 'undefined') {
                let header = document.getElementById('header');
                header.style.zIndex = '1';
            }

            return (
                <div className={bem('reducedHeader', prehomepage ? 'prehomepage' : null)}>
                    <a href={`/${locale.id}/`} alt={`${messages.brand()} Logo`} className={bem('logoReduced')} />
                    <div className={bem('checkoutMessage')}>{prehomepage ? '' : messages.checkout.copy()}</div>
                </div>
            );
        }

        return (
            <OptimizelyFeature feature={['web00191_minimisedheader_sitewide_desktop']}>
                {props => {
                    const [isLoading, setIsLoading] = useState(!!props?.optimizely);
                    // const [isSifuOptimizelyTestRunning, setIsSifuOptimizelyTestRunning] = useState(false);

                    props?.optimizely?.onReady?.({ timeout: 2000 })?.then?.(() => {
                        setIsLoading(false);
                    });

                    // check for an experiment that is also running. uses component Optimizely instance to decide instead of this feature's instance
                    const isSifuExpRunning = this.props?.optimizely?.decide('sifu_optimizely_test')?.enabled;

                    const { variables, variationKey } = props;
                    const minimisedHeaderModifier =
                        ['v1_minimisedheader'].includes(variationKey) && !isLoading && variables.minimisedHeader ? 'minimisedHeaderExperiment' : '';

                    return (
                        <div className={bem(null, minimisedHeaderModifier)}>
                            {isSifuExpRunning && <span style={{ display: 'none' }} />}
                            <HeaderNotifications messages={messages} locale={locale} config={config} />
                            <div className={bem('container', minimisedHeaderModifier)}>
                                <Nav className={bem('navigation', 'mobile')} mobile {...this.props} isSignedIn={isSignedIn} />
                                <div className={bem('genderSwitch', minimisedHeaderModifier)}>
                                    <GenderSwitch messages={messages} locale={locale.id} activeGender={multiShopCookie} componentType="header" />
                                </div>
                                <div className={bem('account')}>
                                    {isSignedIn ? (
                                        <>
                                            <a
                                                className={bem('icon', { account: true, menswearEnabled })}
                                                onMouseOver={this.handleIconInteraction}
                                                data-icon="my account"
                                                href={`/${locale.id}${messages.nav.myAccount.href()}`}
                                            />
                                            <div key="myAccount" className={bem('accountTitle', { menswearEnabled })}>
                                                <a
                                                    href={`/${locale.id}${messages.nav.myAccount.href()}`}
                                                    data-icon="my account"
                                                    onClick={this.handleIconInteraction}
                                                    onMouseOver={this.handleIconInteraction}
                                                >
                                                    {user.response.firstName
                                                        ? user.response.firstName.length < 12
                                                            ? `${messages.nav.myAccount.text({ name: user.response.firstName })}`
                                                            : messages.nav.account.text()
                                                        : messages.nav.account.text()}
                                                </a>
                                                <span className={bem('signout', null, 'signout')} onClick={logOut}>
                                                    <a>{messages.nav.signOut.text()}</a>
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <MyAccountNav locale={locale} messages={messages} menswearEnabled={menswearEnabled} />
                                    )}
                                </div>
                                {!menswearEnabled && (
                                    <div className={bem('localeSwitch')}>
                                        <LocaleSwitchContainer messages={messages} />
                                    </div>
                                )}

                                <a
                                    href={`/${locale.id}/`}
                                    alt={`${messages.brand()} Logo`}
                                    className={bem('logo', minimisedHeaderModifier)}
                                    aria-label="The outnet logo"
                                />
                                <SearchContainer className={bem('search', minimisedHeaderModifier)} messages={messages} />
                                <div className={bem('wishlist', minimisedHeaderModifier)}>
                                    <a
                                        className={bem('icon', 'wishlist')}
                                        href={`/${locale.id}${messages.nav.wishlist.href()}`}
                                        data-icon="wish list"
                                        onClick={this.handleIconInteraction}
                                        onMouseOver={this.handleIconInteraction}
                                        aria-label="my wishlist"
                                    />
                                </div>
                                <PromptBasketItem messages={messages} config={config} error={false} setPopoverError={() => {}}>
                                    <BasketContainer messages={messages} className={bem('basket')} />
                                </PromptBasketItem>
                            </div>
                            <Nav
                                {...this.props}
                                className={bem('navigation')}
                                minimisedHeaderModifier={minimisedHeaderModifier}
                                onInteraction={this.setCookie}
                            />
                        </div>
                    );
                }}
            </OptimizelyFeature>
        );
    }
}

Header.propTypes = {
    messages: PropTypes.shape({
        brand: PropTypes.func.isRequired
    }).isRequired,
    reduced: PropTypes.bool
};

export default withOptimizely(connect(getHeader, { logOut })(Header));
