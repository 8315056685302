import { ResponseError } from '@ynap/api-utils';
import { LOG_OUT_REQUEST, LOG_OUT_SUCCESS, LOG_OUT_ERROR } from '../constants/actionTypes';
import { logOut as logOutApi } from '../../api-utils';
import getWCSHost from '../../api-utils/getWCSHost';
const logOutRequest = () => ({
    type: LOG_OUT_REQUEST
});

const logOutSuccess = () => ({
    type: LOG_OUT_SUCCESS
});

const logOutError = error => ({
    type: LOG_OUT_ERROR,
    error
});

const logOut = () => (dispatch, getState, fetch) => {
    const { config, uberToken, locale } = getState();
    const { brandId, wcs, webPush } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const { country } = locale;

    dispatch(logOutRequest());

    function delete_cookie() {
        document.cookie = 'ubertoken=expired; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure';
    }

    function resetNotifications() {
        if (webPush && window.webPushManagerAPI) {
            window.webPushManagerAPI.resetUserId();
        }
    }

    return fetch(logOutApi({ baseUrl, country, brandId, clientId, uberToken }))
        .then(response => {
            if (response.ok) {
                dispatch(logOutSuccess());
                delete_cookie();
                resetNotifications();
                return window.location.assign(`/${locale.id}/account/user/login`);
            }
            throw new ResponseError(response);
        })
        .catch(error => {
            // TODO: ubertoken does not appear to be invalidated! we'll see this a lot until this is fixed.
            dispatch(logOutError(error.message));
            throw error;
        });
};

export default logOut;
