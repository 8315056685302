import React from 'react';
import PropTypes from 'prop-types';

import CookieBanner from '../CookieBanner/CookieBanner';

const HeaderNotifications = ({ messages, locale, config }) => {
    const enableCookieToast = config.cookieToast.enabledInCountries.includes(locale.country);
    return enableCookieToast ? <CookieBanner messages={messages} locale={locale} /> : null;
};

HeaderNotifications.propTypes = {
    messages: PropTypes.object.isRequired,
    config: PropTypes.shape({
        cookieToast: PropTypes.array.isRequired
    }).isRequired,
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired
};

export default HeaderNotifications;
