import { getIsSignedIn } from './';

const getFooter = state => {
    const IsSignedIn = getIsSignedIn(state);
    const { reduced, locale, config, multiShopCookie, webpush } = state;

    return { reduced, locale, config, IsSignedIn, multiShopCookie, webpush };
};

export default getFooter;
