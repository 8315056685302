import React from 'react';
import Svg from './Svg';
import Tick from './Tick';

export const TickIcon = ({ className, size }) => (
    <span className={className}>
        <Svg width={size} height={size} viewport={`0 0 ${size} ${size}`}>
            <Tick />
        </Svg>
    </span>
);
