import React, { PureComponent } from 'react';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('MyAccountNav');

class MyAccountNav extends PureComponent {
    state = {
        open: false
    };

    render() {
        const { locale, messages, reduced, menswearEnabled } = this.props;
        const { nav } = messages;

        return (
            <>
                <a
                    className={bem('icon', { account: true, menswearEnabled })}
                    href={`/${locale.id}${messages.nav.signIn.href()}`}
                    onMouseOver={this.handleIconInteraction}
                    data-icon="my account"
                    aria-label="Go to my account"
                />
                <div key="signIn" className={bem('accountTitle', { reduced, menswearEnabled })}>
                    <a href={`/${locale.id}${nav.signIn.href()}`}>{nav.signIn.text()}</a>
                </div>
            </>
        );
    }
}

export default MyAccountNav;
