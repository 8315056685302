import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { withOptimizely } from '@optimizely/react-sdk';
import BEMHelper from '@ynap/bem-helper';
import { trackSubCategoryClick } from './analytics';
import getTargetURL from '../../../../Utils/getTargetURL';

const bem = new BEMHelper('list');

@track()
class List extends PureComponent {
    @track((props, state, [event]) => trackSubCategoryClick(props.mobile ? 'side nav' : 'top nav', event.target.getAttribute('data-target').toLowerCase()))
    handleSubItemClick = (subItemLayoutVariant) => {
        const { optimizely } = this.props;
        const fRLayoutVariant = subItemLayoutVariant === "site-furniture-secondary-menu-front-row";
        optimizely.onReady().then(() => {
            optimizely.track('global_meganav_clicks');
            optimizely.track('global_navandsearch_clicks');
            fRLayoutVariant && optimizely.track('global_frontrow_menu');
        });
    };

    renderFirstLevel = firstLevel => {
        return firstLevel
            ? firstLevel.map(subItem => {
                const subItemLayoutVariant = subItem.layoutVariant
                return subItem.title ? (
                    <li key={subItem.title}>
                        {getTargetURL(subItem) ? (
                            <a
                                href={getTargetURL(subItem)}
                                className={bem('title', subItemLayoutVariant)}
                                data-target={subItem.title}
                                data-nav-type={subItemLayoutVariant}
                                onClick={() => this.handleSubItemClick(subItemLayoutVariant)}
                            >
                                {subItem.title.replace(/(&nbsp;)/g, ' ')}
                            </a>
                        ) : (
                            <div>{subItem.title.replace(/(&nbsp;)/g, ' ')}</div>
                        )}
                    </li>
                ) : null
            }) : null;
    };

    renderSecondLevel = secondLevel => {
        return secondLevel
            ? secondLevel.map(subItem => {
                const subItemLayoutVariant = subItem.layoutVariant
                return subItem.title ? (
                    <li key={subItem.title}>
                        {getTargetURL(subItem) ? (
                            <a
                                href={getTargetURL(subItem)}
                                className={bem('title', subItem.layoutVariant)}
                                data-target={subItem.title}
                                data-nav-type={subItem.layoutVariant}
                                onClick={() => this.handleSubItemClick(subItemLayoutVariant)}
                            >
                                {subItem.title.replace(/(&nbsp;)/g, ' ')}
                            </a>
                        ) : (
                            <div>{subItem.title.replace(/(&nbsp;)/g, ' ')}</div>
                        )}
                    </li>
                ) : null
            }) : null;  
    };

    render() {
        const { list, level } = this.props;

        const firstLevel = list.items && list.items[level] && list.items[level].items ? list.items[level].items : null;

        const secondLevel = firstLevel && firstLevel[0].items ? firstLevel[0].items : null;

        return (
            <Fragment>
                {this.renderFirstLevel(firstLevel)}
                {this.renderSecondLevel(secondLevel)}
            </Fragment>
        );
    }
}

List.propTypes = {
    list: PropTypes.shape({
        items: PropTypes.object
    }),
    level: PropTypes.number,
    optimizely: PropTypes.shape({
        track: PropTypes.func,
        onReady: PropTypes.func
    })
};

export default withOptimizely(List);
