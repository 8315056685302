import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { CombinedImage } from '@ynap/image';
import { Button } from '@ynap/button';
import { connect } from 'react-redux';
import { getBasket } from '../../../../../state/selectors';
import { productSelectors } from '@ynap/product-state';
import { basketClearLastItem } from '../../../../../state/actions';

const { getProductImageFromTemplate } = productSelectors;

const bem = new BEMHelper('PromptBasketItem');
const popoverBem = new BEMHelper('sf-HeaderPopover');

const PromptBasketItem = props => {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(null);
    const [isCheckout, setIsCheckout] = useState(false);

    const { messages, children, lineItems, locale, lastItemAdded } = props;

    useEffect(() => {
        setIsCheckout(window?.location?.href?.includes('/checkout'));
    }, []);

    const closePopover = () => {
        setOpen(false);
    };

    const openPopover = () => {
        setOpen(true);
    };

    // wait 4sec and auto hide
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                closePopover();
            }, 4000);
        }
    }, [open]);

    useEffect(() => {
        if (lineItems && lineItems.length && lastItemAdded) {
            const foundItem = lineItems.find(li => li.partNumber === lastItemAdded);
            if (foundItem) {
                setItem(foundItem);
                openPopover();
                props.dispatch(basketClearLastItem());
            }
        }
    }, [lineItems, lastItemAdded]);

    return (
        <>
            {children}
            {!isCheckout && <PromptComponent item={item} messages={messages} locale={locale} open={open} closePopover={closePopover} />}
        </>
    );
};

const PromptComponent = props => {
    const { messages, locale, item, open, closePopover } = props;

    if (!item) return null;

    const shouldOpen = open && item;
    const shotType = item?.imageViews ? item?.imageViews[0] : '';
    const { url, srcSet } = getProductImageFromTemplate(item.imageTemplate, shotType, [120], item.quality);
    return (
        <div className={bem()}>
            <div className={bem('container', open ? 'open' : 'hide', popoverBem('container', 'basket'))}>
                {shouldOpen && item && (
                    <div className={bem('popover', ['basket', 'large', 'right'], popoverBem('popover', 'basket'))}>
                        <button className={bem('close', 'button')} type="button" onClick={closePopover} tabIndex="0" aria-label="Close" />
                        <div className={bem('product-details')}>
                            <div className={bem('imageContainer')}>
                                <CombinedImage src={url} primarySrcset={srcSet} alt={item.orderItemId} computeAttributes={false} imageSrcsetSize={'120px'} />
                            </div>
                            <div className={bem('details')}>{messages.promptBasketItem.title()}</div>
                        </div>
                        <Button type="primary-icon" className={bem('cta')} component="a" href={`/${locale.id}${messages.nav.checkout.href()}`}>
                            {messages.promptBasketItem.button()}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

PromptBasketItem.propTypes = {
    messages: PropTypes.shape({
        promptBasketItem: PropTypes.shape({
            title: PropTypes.func.isRequired,
            button: PropTypes.func.isRequired
        }).isRequired,
        nav: PropTypes.shape({
            nav: PropTypes.shape({
                checkout: PropTypes.shape({
                    href: PropTypes.func.isRequired
                }).isRequired
            }).isRequired
        }).isRequired
    }).isRequired,
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    lineItems: PropTypes.array.isRequired,
    lastItemAdded: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any
};

const mapStateToProps = state => {
    return {
        ...getBasket(state)
    };
};

export default connect(mapStateToProps)(PromptBasketItem);
