const parser = require('ua-parser-js');

// Device Tags
const ANDROID_DEVICE = 'ANDROID_DEVICE';
const IOS_DEVICE = 'IOS_DEVICE';
const GENERIC_DEVICE = 'GENERIC_DEVICE';
const DESKTOP_DEVICE = 'DESKTOP_DEVICE';

//User Tags
const HAS_ORDERS = 'HAS_ORDERS';
const HAS_NO_ORDERS = 'HAS_NO_ORDERS';
const UNKNOWN_USER = 'UNKNOWN_USER';

export const userSegmentsBuilder = userInfo => {
    let segments = [];

    if (typeof window !== 'undefined') {
        const UA = parser(window?.navigator?.userAgent); //parse User Agent string into object
        if (UA?.device?.type === 'mobile' || UA?.device?.type === 'tablet') {
            const deviceSegment = UA.os.name === 'Android' ? ANDROID_DEVICE : UA.os.name === 'iOS' ? IOS_DEVICE : GENERIC_DEVICE;
            segments.push(deviceSegment);
        } else {
            segments.push(DESKTOP_DEVICE);
        }
    }

    const hasOrders = userInfo?.hasOrder === 'true';
    const hasNoOrders = userInfo?.hasOrder === 'false';
    if (!userInfo?.hasOrder) {
        segments.push(UNKNOWN_USER);
    } else if (hasOrders) {
        segments.push(HAS_ORDERS);
    } else if (hasNoOrders) {
        segments.push(HAS_NO_ORDERS);
    }
    return segments;
};
