import * as React from 'react';
import BEMHelper from '@ynap/bem-helper';
import { useTracking } from 'react-tracking';
import { GenderSwitchToggle } from './analytics/';

export const bem = new BEMHelper('GenderSwitch');

const GenderSwitch = ({ messages, locale, activeGender, handleToggleFn, componentType }) => {
    const { Track, trackEvent } = useTracking();

    const handleClick = gender => {
        trackEvent(GenderSwitchToggle(gender, componentType));
        handleToggleFn?.(gender);
    };

    return (
        <Track>
            <div className={bem()}>
                <a onClick={() => handleClick('womens')} href={locale && `/${locale}/shop/`} className={bem('link', activeGender === 'womens' ? 'active' : '')}>
                    {messages.multishop.womens()}
                </a>
                <a
                    onClick={() => handleClick('mens')}
                    href={locale && `/${locale}/shop/mens/`}
                    className={bem('link', [activeGender === 'mens' ? 'active' : '', 'mens'])}
                >
                    {messages.multishop.mens()}
                </a>
            </div>
        </Track>
    );
};

export default GenderSwitch;
