export const FETCH_MENUS_REQUEST = 'FETCH_MENUS/REQUEST';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS/SUCCESS';
export const FETCH_MENUS_ERROR = 'FETCH_MENUS/FAILURE';
export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES/REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES/SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES/FAILURE';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const FETCH_CONTACT_DETAILS_REQUEST = 'FETCH_CONTACT_DETAILS_REQUEST';
export const FETCH_CONTACT_DETAILS_SUCCESS = 'FETCH_CONTACT_DETAILS_SUCCESS';
export const FETCH_CONTACT_DETAILS_ERROR = 'FETCH_CONTACT_DETAILS_ERROR';

export const REGISTER_EMAIL_REQUEST = 'REGISTER_EMAIL_REQUEST';
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS';
export const REGISTER_EMAIL_ERROR = 'REGISTER_EMAIL_ERROR';
export const REGISTER_EMAIL_RESET = 'REGISTER_EMAIL_RESET';

export const RIBBON_REQUEST = 'RIBBON_RIBBON';
export const RIBBON_SUCCESS = 'RIBBON_SUCCESS';
export const RIBBON_ERROR = 'RIBBON_ERROR';

export const TOGGLE_REDUCED = 'TOGGLE_REDUCED';
export const TOGGLE_REDUCED_SIDEBAR = 'TOGGLE_REDUCED_SIDEBAR';

export const FETCH_BASKET_REQUEST = 'FETCH_BASKET_REQUEST';
export const FETCH_BASKET_SUCCESS = 'FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_ERROR = 'FETCH_BASKET_ERROR';
export const BASKET_CLEAR_LAST_ITEM = 'BASKET_CLEAR_LAST_ITEM';

export const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH/REQUEST';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH/SUCCESS';
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH/FAILURE';
export const FETCH_SEARCH_RESET = 'FETCH_SEARCH/RESET';

export const FETCH_SUGGESTIONS_REQUEST = 'FETCH_SUGGESTIONS/REQUEST';
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS/SUCCESS';
export const FETCH_SUGGESTIONS_ERROR = 'FETCH_SUGGESTIONS/FAILURE';
export const FETCH_SUGGESTIONS_RESET = 'FETCH_SUGGESTIONS/RESET';

export const SET_LOCALESWITCH_VISIBILITY = 'SET_LOCALESWITCH_VISIBILITY';
export const SET_WEBPUSH_ANALYTICS_DATA = 'SET_WEBPUSH_ANALYTICS_DATA';
