export default function handleHeaderOnScrollBehaviour(headerOnUpScrollEnabled = false) {
    const headerWrapper = document.getElementById('header-wrapper');
    const headerMain = document.getElementById('header-main');
    const infoBar = document.getElementById('infoBar');
    let prevScrollPos = window.pageYOffset;

    headerWrapper.style.height = headerMain.offsetHeight + 'px';
    headerOnUpScrollEnabled && headerMain.classList.add('animation-disabled'); // Related to web00190_headeronupscroll_sitewide_desktop AB test

    const resizeObserver = new ResizeObserver(entries => {
        const height = Math.round(entries[0].contentRect.height);

        if (headerWrapper.offsetHeight !== height) {
            headerWrapper.style.height = height + 'px';
        }
    });

    resizeObserver.observe(headerMain);

    window.onscroll = function() {
        const currentScrollPos = window.pageYOffset;
        if (currentScrollPos > headerMain.offsetHeight) {
            infoBar.classList.add('scrolltohide');
        } else {
            infoBar.classList.remove('scrolltohide');
        }

        /* START of AB test web00190_headeronupscroll_sitewide_desktop code */
        if (headerOnUpScrollEnabled) {
            if (
                (prevScrollPos > currentScrollPos &&
                    ['animation-disabled', 'unpinned', 'fixed'].every(c => headerMain.classList.contains(c)) &&
                    headerWrapper.getBoundingClientRect().bottom >= 0) ||
                currentScrollPos === 0
            ) {
                headerMain.classList.remove('unpinned', 'fixed');
            }

            if (headerWrapper.getBoundingClientRect().bottom < 0) {
                if (prevScrollPos > currentScrollPos) {
                    headerMain.classList.remove('unpinned', 'animation-disabled');
                    headerMain.classList.add('pinned', 'fixed');
                } else {
                    headerMain.classList.remove('pinned');
                    headerMain.classList.add('unpinned', 'fixed');
                }
            } else if (headerWrapper.getBoundingClientRect().top == 0) {
                headerMain.classList.remove('pinned', 'fixed');
                headerMain.classList.add('animation-disabled');
            }
        }
        /* END of AB test web00190_headeronupscroll_sitewide_desktop code */
        prevScrollPos = currentScrollPos;
    };
}
