const getSearch = state => {
    const { locale, config, searchSuggestions, searchProducts } = state;
    const result = {
        ...(searchSuggestions.response.body ? { suggestions: searchSuggestions.response.body } : {}),
        ...(searchProducts.response.body ? { products: searchProducts.response.body } : {})
    };
    const isFetching = searchSuggestions.isFetching || searchProducts.isFetching;

    return {
        config,
        locale,
        result,
        isFetching
    };
};

export default getSearch;
