import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import BEMHelper from '@ynap/bem-helper';
import dispatch from '@ynap/analytics/src/dispatch';
import { hasTag, getTags } from '@ycos/coremedia-renderer';
import { withOptimizely } from '@optimizely/react-sdk';
import intersection from 'lodash.intersection';
import SecondaryMenu from '../SecondaryMenu/SecondaryMenu';
import MenuSlide from '../../../Utils/MenuSlide/MenuSlide';
import { trackTopNavCategoryClick, trackSideNavDrillDown } from './analytics';
import { addPromoInfoMiddleware, getPromoDisplayData } from '../../Promo/analytics';
import { getUserSegments } from '../../../../../../state';

export const bem = new BEMHelper('CategoryMenu');
export const CUSTOMER_SEGMENTS_TAG = 'CustomerSegments';

@track(props => {
    return {
        attributes: {
            navType: 'mainnav',
            navParent: props.item.title?.toLowerCase()
        }
    };
})
export class CategoryMenu extends PureComponent {
    state = {
        open: false,
        hover: false,
        highlight: false
    };

    @track((props, state, [event]) => {
        const category = event.target.text?.toLowerCase();
        const parentCategory = props.item.title?.toLowerCase();

        return props.desktop ? trackTopNavCategoryClick(category, parentCategory) : trackSideNavDrillDown(category);
    })
    handleSlideWithAnalytics = (event, checkTag, item) => {
        const optimizely = window.optimizelyCreateInstance;
        optimizely?.onReady().then(() => {
            optimizely.track('global_meganav_clicks');
            optimizely.track('global_navandsearch_clicks');
            checkTag && optimizely.track('visits_to_frontrow');
        });

        this.handleSlide(event, item);
    };

    handleSlide = (e, item) => {
        if (this.props.desktop) {
            return;
        }
        e.preventDefault();
        if (item) {
            this.trackPromoDisplay(item);
        }
        this.setState({
            open: !this.state.open
        });
    };

    trackPromoDisplay = item => {
        const promoData = getPromoDisplayData(item);
        if (promoData) {
            dispatch(promoData);
        }
    };

    handleHover = item => {
        const { hover } = this.state;
        const { desktop } = this.props;

        if (desktop) {
            this.trackPromoDisplay(item);
        }

        if (!hover) {
            this.setState({
                hover: true
            });
        }
    };

    handleHighlight = tags => {
        // passes the color if present to state
        if (tags && tags[0].name) {
            this.setState({ highlight: tags[0].name });
        }
    };

    shouldShowCategoryMenu = (menuItem, userSegments) => {
        const categorySegmentTargets = getTags(menuItem?.tags, CUSTOMER_SEGMENTS_TAG);

        // user segment matches Customer Segment set in CM
        const userSegmentsMatches = intersection(categorySegmentTargets, userSegments).length > 0;

        // Should be visible if no conditional tags on item
        const noCMSegmentation = !categorySegmentTargets.length;

        // Category should be visible if:
        // - No segmentation added in Coremedia OR
        // - User matches the Customer Type set in CM
        let isCategoryVisible = noCMSegmentation || userSegmentsMatches;

        return isCategoryVisible;
    };

    componentDidMount() {
        this.handleHighlight(this.props.item.tags);
    }

    render() {
        const { item, desktop, messages, config, mobile, minimisedHeaderModifier, FRMenuItemClass, user, optimizely } = this.props;
        const { target = {} } = item;
        const items = addPromoInfoMiddleware(item.items, item.layoutVariant);
        const { hover } = this.state;
        const checkTag = hasTag(item && item.tags, 'frontrow');
        const isSale = hasTag(item && item.tags, 'isSale');
        const userSegments = getUserSegments(this.props.user?.response);
        const categoryMenuContent = this.shouldShowCategoryMenu(item, userSegments);

        return categoryMenuContent ? (
            <li className={bem()} key={item.title} aria-label={target.name || item.title}>
                <a
                    onClick={e => {
                        // TODO: remove after WEB00526/WEB00527 are finished
                        isSale && optimizely?.track('global_nav_to_sale_plp');
                        isSale && optimizely?.track('global_sale_plp');
                        // End of WEB00526/WEB00527
                        this.handleSlideWithAnalytics(e, checkTag, { ...item, items });
                    }}
                    className={bem('title', [item.layoutVariant, this.state.highlight, minimisedHeaderModifier], checkTag && FRMenuItemClass)}
                    href={item.targetURL}
                    data-target={item.title || target.name}
                    onMouseOver={() => this.handleHover({ ...item, items })}
                >
                    <div>{item.title}</div>
                </a>
                <MenuSlide
                    handleClick={this.handleSlide}
                    toggle={desktop || this.state.open}
                    overlay={desktop}
                    bodyLock={!desktop}
                    FRMenuItemClass={FRMenuItemClass}
                >
                    <SecondaryMenu
                        {...this.props}
                        item={{ ...item, items }}
                        messages={messages}
                        handleClick={this.handleSlide}
                        imageBaseUrl={config.imageSphere.baseUrl}
                        mobile={!!mobile}
                        hover={hover}
                        FRMenuItemClass={FRMenuItemClass}
                        user={user}
                    />
                </MenuSlide>
            </li>
        ) : null;
    }
}

CategoryMenu.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        targetURL: PropTypes.string.isRequired,
        tags: PropTypes.array,
        layoutVariant: PropTypes.string
    }).isRequired,
    desktop: PropTypes.bool,
    getProductCategoryUrl: PropTypes.func,
    mobile: PropTypes.bool,
    minimisedHeaderModifier: PropTypes.string
};

CategoryMenu.defaultProps = {
    desktop: false,
    getProductCategoryUrl: () => {}
};

export default withOptimizely(CategoryMenu);
