export const createpromoObject = (link, plainText, icon, position, contentId, tags, userSegments) => {
    return {
        title: plainText?.trim(), // content title
        tags: {
            customersegments: userSegments,
            promotype: tags ? tags.join() : ''
        },
        imageUrl: icon, // image or icon url
        destinationUrl: link ? link : '', // URL path of element anchor tag
        position: position + 1, // Position of element in its parent.
        componentType: 'benefitsBar', // benefitbar, privatesalebanner, etc.
        contentId: contentId // coremedia content ID
    };
};

export const trackOnPageLoad = (tracking, analyticsItems) =>
    tracking.trackEvent({
        event: `promo module view`,
        category: {
            primaryCategory: 'site merchandising',
            subCategory: 'benefits bar'
        },
        attributes: {
            promo: analyticsItems
        }
    });

export const trackOnClick = (tracking, promoObject) =>
    tracking.trackEvent({
        event: `promo module click`,
        category: {
            primaryCategory: 'site merchandising',
            subCategory: 'homepage'
        },
        attributes: {
            promo: [promoObject]
        }
    });
