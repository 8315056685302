const getContactDetails = state => {
    const { contactDetails } = state;

    if (!contactDetails || contactDetails.error) {
        return {
            responseError: true
        };
    }

    const { telephone, email } = contactDetails?.response?.body?.customerCare || {};
    return { telephone, email };
};

export default getContactDetails;
