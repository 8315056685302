import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Message from '../Message/Message';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('CookieBanner');

class CookieBanner extends Component {
    static propTypes = {
        messages: PropTypes.shape({
            cookieBanner: PropTypes.shape({
                title: PropTypes.func.isRequired,
                message: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired,
                cta: PropTypes.func.isRequired
            }).isRequired
        }).isRequired,
        locale: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    };

    state = {
        showConsentCookiePrompt: false
    };

    dismissMessage = () => {
        this.setState({ showConsentCookiePrompt: false }, () => {
            localStorage?.setItem('cookiePolicyViewed', 'true');
            window.SF?.notifyCookieUpdate();
        });
    };

    componentDidMount() {
        const cookiePolicyViewed = localStorage?.getItem('cookiePolicyViewed') === 'true';
        this.setState({ showConsentCookiePrompt: !cookiePolicyViewed });
    }

    render() {
        const {
            messages: { cookieBanner },
            locale
        } = this.props;
        const { showConsentCookiePrompt } = this.state;

        const copy = (
            <>
                {cookieBanner.message()}
                <a className={bem('link')} href={`/${locale.id}${cookieBanner.href()}`}>
                    {cookieBanner.cta()}
                </a>
            </>
        );
        return showConsentCookiePrompt ? <Message className={bem()} title={cookieBanner.title()} copy={copy} onClose={this.dismissMessage} /> : null;
    }
}

export default CookieBanner;
