import { FETCH_CONTACT_DETAILS_REQUEST, FETCH_CONTACT_DETAILS_SUCCESS, FETCH_CONTACT_DETAILS_ERROR } from '../constants/actionTypes';

import apiFetchReducer from './api-fetch-reducer';

const contactDetails = apiFetchReducer(
    FETCH_CONTACT_DETAILS_REQUEST,
    FETCH_CONTACT_DETAILS_SUCCESS,
    FETCH_CONTACT_DETAILS_ERROR
);

export default contactDetails;
