import { raygun } from '@ynap/clientside-monitoring';

export default function setAffiliateCookie() {
    var urlParams = new URLSearchParams(window.location.search);
    const tpID = getTpValue(urlParams);

    if (tpID) {
        const sessionExpiry = new Date();
        sessionExpiry.setHours(sessionExpiry.getHours() + 4);
        document.cookie = `tp_session=${tpID}; expires=${sessionExpiry.toUTCString()};`;

        if (!hasCookie('tp_historic')) {
            const historicExpiry = new Date();
            historicExpiry.setDate(historicExpiry.getDate() + 90);
            document.cookie = `tp_historic=${tpID}; expires=${historicExpiry.toUTCString()};`;
        }
    }
}

function getTpValue(urlParams) {
    const tpID = urlParams.get('tp');

    if (tpID?.length > 49) {
        // https://jira.nap/browse/AIM-2678
        // Some affiliate urls being generated with invalid url params, if too long for WCS to handle trim to 6.
        const newTpID = tpID.slice(0, 6);
        raygun.helpers.agent('send', {
            error: 'Invalid affiliate url param',
            tags: ['sitefurniture', 'affiliates'],
            customData: { url: window.location.href, newTpID }
        });
        return newTpID;
    }

    return tpID;
}

function hasCookie(name) {
    const cookieRegex = new RegExp(`${name}=([^;]+)`);
    const cookieMatches = document.cookie.match(cookieRegex);
    return cookieMatches?.length > 0;
}
