/**
 * @param {'click' | 'mouseover'} interaction
 * @param {string} iconLabel
 */
export const trackIconInteraction = (iconLabel, interaction = 'click') => ({
    event: `${iconLabel} icon - ${interaction}`,
    effect: interaction === 'click' ? 'navigate' : 'flyout',
    category: {
        primaryCategory: iconLabel
    }
});
