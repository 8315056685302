import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import Flag from '@ynap/flag';
import track from 'react-tracking';
import LocaleSwitchOverlay from './LocaleSwitchOverlay/LocaleSwitchOverlay';
import LanguageToggle from './LanguageToggle/LanguageToggle';
import { trackCountrySelectOpen } from './analytics';
import handleChangeLanguage from './utils/handleChangeLanguage';

const bem = new BEMHelper('LocaleSwitch');

@track(() => ({
    category: {
        primaryCategory: 'change shipping country'
    }
}))
class LocaleSwitch extends PureComponent {
    state = {
        showOverlay: false
    };

    @track(props => !props.visible && trackCountrySelectOpen())
    toggleCountryOverlay = () => {
        this.setState({ showOverlay: !this.state.showOverlay });
    };

    render() {
        const { showOverlay } = this.state;

        if (this.props.responseError) return null;

        const {
            messages,
            currentCountryName,
            currentCountryCode,
            locale: { language: currentLanguage },
            availableLanguages
        } = this.props;

        const showLanguageToggle = availableLanguages.length > 1;

        const { changeCountry, languages } = messages.localeSwitch;

        return (
            <section>
                <h4 className={bem('title')}>{changeCountry.title()}</h4>
                <div className={bem('details')}>
                    <div className={bem('countrySwitch')} onClick={this.toggleCountryOverlay}>
                        <Fragment>
                            <Flag className={bem('flag')} countryISO={currentCountryCode} />
                            <span className={bem('countryWithFlag', showLanguageToggle && 'languageToggleDisplayed')}>{currentCountryName}</span>
                        </Fragment>
                    </div>
                    <span className={bem('diamond-divider')} />
                    {showLanguageToggle && (
                        <LanguageToggle
                            countryCode={currentCountryCode}
                            currentLanguage={currentLanguage}
                            availableLanguages={availableLanguages}
                            messages={languages}
                            modifier={'burger-menu'}
                            handleChangeLanguage={handleChangeLanguage}
                        />
                    )}
                </div>
                {showOverlay ? <LocaleSwitchOverlay {...this.props} toggleCountryOverlay={this.toggleCountryOverlay} /> : null}
            </section>
        );
    }
}

const propTypes = {
    messages: PropTypes.shape({
        localeSwitch: PropTypes.shape({
            changeCountry: PropTypes.shape({
                title: PropTypes.func.isRequired
            }),
            languages: PropTypes.shape({
                en: PropTypes.func,
                ar: PropTypes.func
            })
        })
    }),
    currentCountryName: PropTypes.string.isRequired,
    currentLanguageName: PropTypes.string.isRequired,
    availableLanguages: PropTypes.array.isRequired
};

LocaleSwitch.propTypes = propTypes;

export default LocaleSwitch;
