import { FETCH_BASKET_REQUEST, FETCH_BASKET_SUCCESS, FETCH_BASKET_ERROR, BASKET_CLEAR_LAST_ITEM } from '../constants/actionTypes';

const initialState = {
    isFetching: false,
    response: {},
    error: null,
    lastItemAdded: undefined
};

const basket = (state = initialState, { type, error, response, lastItemAdded }) => {
    switch (type) {
        case FETCH_BASKET_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_BASKET_SUCCESS:
            return {
                isFetching: false,
                response,
                error: null,
                lastItemAdded
            };
        case FETCH_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
                error
            };
        case BASKET_CLEAR_LAST_ITEM:
            return {
                ...state,
                lastItemAdded: undefined
            };
        default:
            return state;
    }
};

export default basket;
