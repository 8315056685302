const getCountries = state => {
    if (state.countries.error || !state.countries.response.body) {
        return {
            responseError: true
        };
    }

    const { config, locale, uberToken } = state;
    const { country } = locale;

    // Countries should be ordered by country name alphabetically (server response is ordered by country code).
    // 'sort' function mutates the source array so use it after 'slice'.
    // 'slice' function creates a copy of the array so 'sort' will mutate the copy, not the original source array.
    const alphabeticalCountries = state.countries.response.body.slice().sort((country1, country2) => country1.countryName?.localeCompare(country2.countryName));

    // convert config to map of countries and hidden languages
    // e.g.: 'ar-*,fr-gb,en-gb,zh-gb => { *: ['ar'], gb: ['fr', 'en', 'zh] }
    // it will not accept '*-gb' or "*-*", because each country should have one language available
    const languageMap = {};
    config?.changeLanguage?.excludeLanguages?.split?.(',')?.map?.(localeToExclude => {
        const [language, country] = localeToExclude.split('-');
        languageMap[country] = [...(languageMap[country] || []), language];
    });

    // if config has languages to remove, map over alphabeticalCountries to find out country and remove them
    const excludeLanguages = (countries, languageMap) => {
        if (Object.keys(languageMap).length) {
            const filteredCountries = alphabeticalCountries.reduce((acc, countryObj) => {
                if (Object.keys(languageMap).includes(countryObj?.countryCode?.toLowerCase())) {
                    const languages = Object.keys(countryObj.languages).reduce((acc, key) => {
                        if (languageMap[countryObj?.countryCode?.toLowerCase()].includes(key)) return acc;
                        return { ...acc, [`${key}`]: countryObj.languages[key] };
                    }, {});
                    if (Object.keys(languages).length === 0) {
                        return acc;
                    } // If all languages excluded, remove country
                    return [...acc, { ...countryObj, languages }];
                }
                return [...acc, countryObj];
            }, []);
            const finalCountries = languageMap['*']?.length
                ? filteredCountries.reduce((acc, countryObj) => {
                      const languages = Object.keys(countryObj.languages).reduce((acc, key) => {
                          if (languageMap['*'].includes(key)) return acc;
                          return { ...acc, [`${key}`]: countryObj.languages[key] };
                      }, {});
                      return [...acc, { ...countryObj, languages }];
                  }, [])
                : filteredCountries;
            return finalCountries;
        }
        return countries;
    };

    const countries = excludeLanguages(alphabeticalCountries, languageMap);

    const currentCountry = countries.find(countryObj => countryObj.countryCode === country);
    const { preferredCurrency, currencies, countryCode, countryName, preferredLanguage, languages } = currentCountry;

    return {
        countries,
        currentCountryName: countryName,
        currentCountryCode: countryCode,
        preferredLanguage,
        preferredCurrency,
        currencySymbol: (currencies[preferredCurrency] || { symbol: '' }).symbol,
        locale,
        availableLanguages: Object.keys(languages),
        config,
        uberToken
    };
};

export default getCountries;
