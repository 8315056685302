const getBasket = state => {
    const { basket, locale } = state;
    const { totalOrderQuantity: count, orderItem } = basket.response;
    const { isFetching, lastItemAdded } = basket;

    return {
        lineItems: orderItem,
        count,
        locale,
        isFetching,
        lastItemAdded
    };
};

export default getBasket;
