import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const getContactDetails = params => {
    const { baseUrl, brandId } = params;

    invariant(baseUrl, 'baseUrl is a required param of getContactDetails()');
    invariant(brandId, 'brandId is a required param of getContactDetails()');

    const headers = {
        'Content-Type': 'application/json',
    };
    const url = `${baseUrl}${brandId}/v1/contacts.json`;

    return authenticatedRequest(new Request(url, { headers: new Headers(headers), method: 'GET' }));
};

export default getContactDetails;
