import React from 'react';
import InfoBarMensWear from './InfoBarMensWear';
import track from 'react-tracking';
import { connect } from 'react-redux';
import { getHeader } from '../../../../../state/selectors';
import InfoBar from './InfoBar';

const InfoBarContainer = props => {
    // TODO: Once all countries have menswear this toggle can be removed and only render InfoBarMensWear
    const showMensWearInfoBar = !props.config.multiShop.disableCountries.includes(props.locale.country);
    return showMensWearInfoBar ? <InfoBarMensWear {...props} /> : <InfoBar {...props} />;
};

export default track({})(connect(getHeader)(InfoBarContainer));
