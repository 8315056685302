export const trackSideNavFlyout = () => ({
    event: 'side nav - open',
    effect: 'flyout'
});

export const trackAccountLinkClick = linkLabel => ({
    event: 'side nav - click',
    effect: 'navigate',
    attributes: {
        navType: 'mainnav',
        navParent: 'header',
        navChild: linkLabel.toLowerCase()
    }
});
