import { Component } from 'react';
import PropTypes from 'prop-types';
import { raygun } from '@ynap/clientside-monitoring';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { setError, childType } = this.props;

        setError(childType);

        raygun?.helpers?.agent('send', {
            error: new Error(error),
            tags: ['sitefurniture', childType],
            customData: errorInfo
        });
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    setError: PropTypes.func,
    childType: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

ErrorBoundary.defaultProps = {
    setError: () => {}
};

export default ErrorBoundary;
