import React, { useState, useEffect, useCallback } from 'react';
import BEMHelper from '@ynap/bem-helper';
import Message from '../Message/Message';
import ToggleSlide from '@ynap/toggle-slide';
import { useTracking } from 'react-tracking';
import { hasCookieConsent, isWebPushSupportedPage } from '../../Utils/webPush';

export const bem = new BEMHelper('Webpush');

const TIME_DAYS = 90;

const shouldPromptUser = timeViewed => {
    if (!timeViewed) true;

    const timeSpanned = Date.now() - timeViewed || 0;
    const timeSpannedInDays = Math.floor(timeSpanned / 60000 / 60 / 24);

    return timeSpannedInDays > TIME_DAYS;
};

const Webpush = ({ messages, analyticsData }) => {
    const [shouldShowWebpush, setShouldShowWebpush] = useState(false);
    const [globalId, setGlobalId] = useState(null);
    const [registed, setRegisted] = useState(false);
    const { trackEvent } = useTracking();

    useEffect(() => {
        if (Object.keys(analyticsData).length > 0 && shouldShowWebpush) {
            trackEvent({
                event: 'web push notification - impression',
                effect: 'notification viewed',
                category: {
                    ...analyticsData
                }
            });
        }
    }, [analyticsData, shouldShowWebpush]);

    const closeNotification = useCallback(() => {
        try {
            setShouldShowWebpush(false);
            localStorage.setItem('webpushViewed', Date.now());
        } catch (error) {}
    }, []);

    const closeNotificationWithAnalytics = useCallback(() => {
        trackEvent({
            event: 'web push notification - dismissed',
            effect: 'web push dismissed',
            category: {
                ...analyticsData
            }
        });
        closeNotification();
    }, [closeNotification, analyticsData]);

    const requestPermission = useCallback(() => {
        try {
            if (window.webPushManagerAPI) {
                window.webPushManagerAPI.register();
                setRegisted(true);
            }
        } catch (error) {}
    }, []);

    const onToggle = useCallback(() => {
        trackEvent({
            event: 'web push notification - opt in',
            effect: 'web push enabled',
            category: {
                ...analyticsData
            }
        });
        requestPermission();

        setTimeout(closeNotification, 1000);
    }, [requestPermission, closeNotification, analyticsData]);

    useEffect(() => {
        if (registed && globalId) {
            const checkGranted = setInterval(() => {
                if (Notification.permission === 'granted') {
                    window.webPushManagerAPI.setUserId(globalId);
                    clearInterval(checkGranted);
                }
                if (Notification.permission === 'denied') {
                    clearInterval(checkGranted);
                }
            }, 3000);

            return () => clearInterval(checkGranted);
        }
    }, [registed, globalId]);

    useEffect(() => {
        if (window.Notification && window.Notification.permission === 'default') {
            const status = shouldPromptUser(localStorage.getItem('webpushViewed'));
            setShouldShowWebpush(status);

            window.SF.getUserSummary().then(user => {
                if (user) {
                    setGlobalId(user.globalId);
                }
            });
        }
    }, []);

    const toggle = (
        <div className={bem('toggle')}>
            <ToggleSlide textLeft={messages.webpush.toggleMessage()} onToggle={onToggle} />
        </div>
    );

    return (
        shouldShowWebpush && (
            <Message className={bem()} title={messages.webpush.title()} copy={messages.webpush.description()} onClose={closeNotificationWithAnalytics}>
                {toggle}
            </Message>
        )
    );
};

const WebpushContainer = ({ messages, config, country, analyticsData }) => {
    const [renderWebpush, setRenderWebpush] = useState(false);

    useEffect(() => {
        const privacyCookie = hasCookieConsent(config, country);
        const supportedPage = isWebPushSupportedPage(location.pathname);
        setRenderWebpush(privacyCookie && supportedPage);

        if (!privacyCookie && supportedPage) {
            const listener = () => setTimeout(() => setRenderWebpush(true), 2000);
            document.addEventListener('cookiechange', listener);

            return () => document.removeEventListener('cookiechange', listener);
        }
    }, []);

    useEffect(() => {
        if (window.Notification?.permission === 'granted' && window.webPushManagerAPI) {
            window.SF.getUserSummary().then(user => {
                const userId = window.webPushManagerAPI.getUserId();
                const globalId = user?.globalId;

                if (globalId && globalId !== userId) {
                    window.webPushManagerAPI.setUserId(globalId);
                } else if (!globalId) {
                    window.webPushManagerAPI.resetUserId();
                }
            });
        }
    }, []);

    return renderWebpush && <Webpush messages={messages} analyticsData={analyticsData} />;
};

export default WebpushContainer;
