import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../constants/actionTypes';
import { getUserSummary, getWCSHost } from '../../api-utils';
import getUserSegments from '../selectors/getUserSegments';

const fetchUserRequest = () => ({
    type: FETCH_USER_REQUEST
});

const fetchUserSuccess = response => ({
    type: FETCH_USER_SUCCESS,
    response
});

const fetchUser = () => async (dispatch, getState) => {
    const { config, uberToken, locale } = getState();
    const { brandId, wcs } = config;
    const { baseUrl, clientId } = getWCSHost({ wcs });
    const { country } = locale;

    dispatch(fetchUserRequest());

    if (!uberToken) {
        window.SF.getUserSummary.resolve(null);
        window.localStorage.setItem('userSummary', JSON.stringify({ isSignedIn: false }));
        return dispatch(fetchUserSuccess({ isSignedIn: false }));
    }

    try {
        const request = getUserSummary({ baseUrl, country, brandId, clientId, uberToken });
        const response = await fetch(request);
        const json = await response.json();
        const isSignedIn = json.registrationStatus.toLowerCase() === 'registeredperson';
        const user = isSignedIn ? json : null;
        window.SF.getUserSummary.resolve(user);

        if (user) {
            const marketingGroups = getUserSegments(json);
            user.marketingGroups = marketingGroups;
        }

        window.localStorage.setItem(
            'userSummary',
            JSON.stringify({
                ...getCachedUser(user),
                isSignedIn
            })
        );

        return dispatch(
            fetchUserSuccess({
                ...user,
                isSignedIn
            })
        );
    } catch (error) {
        window.SF.getUserSummary.resolve(null);
        window.localStorage.setItem('userSummary', JSON.stringify({ isSignedIn: false }));
        return dispatch(fetchUserSuccess({ isSignedIn: false }));
    }
};

const getCachedUser = user => {
    const { firstName, marketingGroups, ordersTotal, hasOrder, parentCustomerURN } = user;
    return {
        firstName,
        marketingGroups,
        ordersTotal,
        hasOrder,
        parentCustomerURN
    };
};

export default fetchUser;
