import { loadScriptWithSrc } from '../../Utils/loadScript';
import { raygun } from '@ynap/clientside-monitoring';

const getScriptConsent = (scriptConfig, consent) => {
    const { privacyId } = scriptConfig;

    if (!privacyId) {
        return true;
    } else {
        const category = consent.categories[privacyId] || {};
        if (category.status === 'on') {
            return true;
        }
    }
    return false;
};

const ScriptContainer = ({ config }) => {
    const { optimizely } = config;

    if (typeof window.cact !== 'undefined') {
        try {
            window.cact('consent.get', ({ consent }) => {
                const optimizelyConsent = getScriptConsent(optimizely, consent);
                if (optimizely.enabled && optimizelyConsent) loadScriptWithSrc(optimizely.path);
            });
        } catch (error) {
            raygun.helpers.agent('send', { error, tags: ['sitefurniture', 'consentFail'] });
        }
    } else {
        if (optimizely.enabled) loadScriptWithSrc(optimizely.path);
    }
    return null;
};

export default ScriptContainer;
