import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const logOut = params => {
    const { baseUrl, brandId, country, clientId, uberToken } = params;

    invariant(baseUrl, 'baseUrl is a required param of logOut()');
    invariant(brandId, 'brandId is a required param of logOut()');
    invariant(country, 'country is a required param of logOut()');
    invariant(uberToken, 'uberToken is a required param of logOut()');
    invariant(clientId, 'clientId is a required param of logOut()');

    const url = `${baseUrl}wcs/resources/store/${brandId}_${country.toLowerCase()}/loginidentity/@self`;

    const headers = {
        'Content-Type': 'application/json'
    };

    return authenticatedRequest(new Request(url, { method: 'DELETE', headers }), uberToken, clientId);
};

export default logOut;
