import React from 'react';
import ReactDOM, { hydrate } from 'react-dom';
import * as optimizelyUtils from '@ynap/optimizely-utils';
import { TonFooter, TonHeader } from '../../components/site-furniture/src';
import InfoBarContainer from '../../components/site-furniture/src/common/InfoBar/InfoBarContainer';
import BenefitsBar from '../../components/site-furniture/src/common/BenefitsBar/BenefitsBar';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from '../../state/configureStore';
import createEventListeners from '../eventListeners';
import setAffiliateCookie from '../setAffiliateCookie';
import SFBrowserAPI from '../../components/site-furniture/services/SFBrowserAPI';
import getMenus from '../../state/selectors/getMenus';
import setMultiShopCookie from '../setMultiShopCookie';
import ErrorBoundary from '../../components/site-furniture/src/common/ErrorBoundary/ErrorBoundary';
import addNotificationListener from '../optimizelyNotificationListener';
import handleHeaderOnScrollBehaviour from '../headerOnScrollHandler';
import { raygun } from '@ynap/clientside-monitoring';
import handleMinimisedHeaderOnScrollBehaviour from '../headerOnScrollHandlerMinimised';
import { getCookie } from '@ynap/redux-cookies-middleware/src/cookieApi';

window.React = React;
window.ReactDOM = ReactDOM;
window.yosOptimizely = optimizelyUtils;

const { createInstance, OptimizelyProvider } = optimizelyUtils;

const hydrateIfExists = (reactEl, domNode) => {
    if (domNode) {
        hydrate(reactEl, domNode);
    }
};

const renderFunction = messages => {
    const { siteFurnitureState, optimizelyState } = window;
    const store = configureStore(siteFurnitureState);
    const state = store.getState();
    const { locale, config, multiShopCookie, bare } = state;
    const {
        assets: { baseUrl },
        multiShop = {},
        optimizely: optimizelyCfg,
        adjust
    } = config;
    const menus = getMenus(state);
    const { headerMenu, footerMenus, privateSale, benefitsBar, responseError } = menus;

    const { sdkKey, disableLogging } = optimizelyCfg || {};
    const datafile = optimizelyState?.datafile;

    const optimizely =
        (sdkKey || datafile) &&
        createInstance({
            sdkKey,
            datafile,
            datafileOptions: {
                autoUpdate: true,
                updateInterval: 600000 // 10 minutes in milliseconds
            },
            disableLogger: disableLogging,
            eventBatchSize: 10,
            eventFlushInterval: 1000
        });

    window.optimizelyCreateInstance = optimizely;
    __webpack_public_path__ = baseUrl;
    SFBrowserAPI(window, store);
    createEventListeners(store);
    setAffiliateCookie();
    multiShop.enabled && setMultiShopCookie(multiShop, locale.country, multiShopCookie);
    addNotificationListener();

    if (!bare) {
        handleHeaderOnScrollBehaviour();
        adjust.enabled &&
            import(/* webpackChunkName: "Adjust" */ '../adjust/adjustWebSdk')
                .then(({ default: configureAdjust }) => {
                    configureAdjust(adjust, locale.language);
                })
                .catch(error => {
                    raygun.helpers.agent('send', {
                        error,
                        tags: ['sitefurniture', 'adjust']
                    });
                });
    }

    // Add attribute to optimizely user if it has been set on previous load in the respective cookie
    const cookie = getCookie('meganav_click');
    let extraAttribute = {};
    if (cookie) {
        extraAttribute = { meganav_click: true };
    }

    const additionalUserAttributes = {
        ...{ browsing_preference: multiShopCookie || null },
        ...extraAttribute
    };

    hydrateIfExists(
        <ErrorBoundary childType="info-bar">
            <ReduxProvider store={store}>
                <OptimizelyProvider optimizely={window.optimizelyCreateInstance} additionalUserAttrs={additionalUserAttributes}>
                    <InfoBarContainer messages={messages[locale.language]} privateSale={privateSale} />
                </OptimizelyProvider>
            </ReduxProvider>
        </ErrorBoundary>,
        document.getElementById('infoBar')
    );

    hydrateIfExists(
        <ErrorBoundary childType="benefits-bar">
            <ReduxProvider store={store}>
                <BenefitsBar messages={messages[locale.language]} benefitsBar={benefitsBar} />
            </ReduxProvider>
        </ErrorBoundary>,
        document.getElementById('benefitsBar')
    );

    hydrateIfExists(
        <ErrorBoundary childType="header">
            <ReduxProvider store={store}>
                <OptimizelyProvider optimizely={window.optimizelyCreateInstance} additionalUserAttrs={additionalUserAttributes}>
                    <TonHeader messages={messages[locale.language]} headerMenu={headerMenu} privateSale={privateSale} responseError={responseError} />
                </OptimizelyProvider>
            </ReduxProvider>
        </ErrorBoundary>,
        document.getElementById('header')
    );

    hydrateIfExists(
        <ErrorBoundary childType="footer">
            <ReduxProvider store={store}>
                <OptimizelyProvider optimizely={window.optimizelyCreateInstance} additionalUserAttrs={additionalUserAttributes}>
                    <TonFooter messages={messages[locale.language]} footerMenus={footerMenus} />
                </OptimizelyProvider>
            </ReduxProvider>
        </ErrorBoundary>,
        document.getElementById('footer')
    );

    optimizely.onReady().then(() => {
        // check both running AB tests
        // WEB00190 and WEB00191
        const isUpscrollExpEnabled = optimizely?.isFeatureEnabled('web00190_headeronupscroll_sitewide_desktop');
        const isMinimisedHeaderExpEnabled = optimizely?.isFeatureEnabled('web00191_minimisedheader_sitewide_desktop');

        if (isUpscrollExpEnabled || isMinimisedHeaderExpEnabled) {
            const { variables: upScrollVariables } = optimizely?.userContext?.decide('web00190_headeronupscroll_sitewide_desktop');
            const { variables: minimisedHeaderVariables } = optimizely?.userContext?.decide('web00191_minimisedheader_sitewide_desktop');

            // checks for urls that end in "/{lang}-{country}/" (e.g.: /en-gb/)
            // since we don't want the minimised header in the splash/gender selection page
            const locationRegex = /.*\/(\w{2})-(\w{2})\/$/;

            // add minimised handler if we are in experiment with minimised header turned on
            if (minimisedHeaderVariables?.minimisedHeader && !window.location.href.includes('/checkout') && !locationRegex.test(window.location.href)) {
                handleMinimisedHeaderOnScrollBehaviour();
            }

            // Upscroll enabled if
            // We have a variable set in the minimised header experiment
            // or if the minimised header experiment is offline (which will default to upscroll due to targetted delivery)
            // We need to remove this after WEB00190 and WEB00191 are completed and move the handler someplace else.
            if ((minimisedHeaderVariables?.headerUpscroll || !isMinimisedHeaderExpEnabled) && !window.location.href.includes('/checkout')) {
                handleHeaderOnScrollBehaviour(upScrollVariables.headerOnUpScrollEnabled);
            }
        }
    });
};

export default renderFunction;
