export function hasTrustCommanderConsent() {
    return !!document.cookie.split('; ').find(cookie => cookie.startsWith('TC_PRIVACY='));
}

export function hasViewedCookieBanner() {
    return localStorage?.getItem('cookiePolicyViewed') === 'true';
}

export function hasCookieConsent(config, country) {
    if (config.trustCommander.enabledInCountries.includes(country)) {
        return hasTrustCommanderConsent();
    } else if (config.trustCommander.cpraEnabledInCountries.includes(country)) {
        return hasTrustCommanderConsent();
    } else if (config.cookieToast.enabledInCountries.includes(country)) {
        return hasViewedCookieBanner();
    } else {
        return true;
    }
}

export function isWebPushSupportedPage(pathname = '') {
    return /\/shop\//.test(pathname) && !/\/shop\/product/.test(pathname) && !/\/shop\/designers$/.test(pathname);
}
